const companiesModel = {
    idPEmpresa: 0,
    usuarioIngreso: "",
    usuarioModificacion: "",
    estado: true,
    nombreEmpresa: "",
    descripcionEmpresa: "",
    ruc: "",
    direccion: ""
};

const statusModel = {
    idPEstado: 0,
    usuarioIngreso: "",
    usuarioModificacion: "",
    estado1: true,
    nombreEstado: "",
    descripcionEstado: ""
};

const usersModel = {
    Id: "",
    UserName: "",
    Email: "",
    Password: "",
    IdRol: "",
    RoleName: ""

};

const userProfileModel = {
    UserName: "",
    CurrentPassword: "",
    NewPassword: "",
    RoleName:""
};

const userChangePassowrd = {
    UserName: "",
    NewPassword: ""
};

const rolesModel = {
    id: "",
    name: "",
    access: [],
    createdBy: ""
};

const catServicesModel = {
    idPCatServicio: 0,
    usuarioIngreso: "",
    usuarioModificacion: "",
    estado: true,
    nombreCatServicio: "",
    descripcionCatServicio: ""
};

const documentsModel = {
    idPDocumento: 0,
    usuarioIngreso: "",
    usuarioModificacion: "",
    estado: true,
    nombreDocumento: "",
    descripcionDocumento: "",
    localidadDoc: "",
    fechaDocumento: "",
    documento1: ""

};

const empleadoModel = {
    idPEmpleado: 0,
    usuarioIngreso: "",
    usuarioModificacion: "",
    estado: true,
    nombreEmpleado: "",
    apellidoEmpleado: "",
    cedula: "",
    descripcionEmpleado: "",
    ciudad: "",
    cargo: "",
    fechaEmpl: "",
    fechaInicial: "",
    fechaFinal: "",
    nombreUsuarioAsignado: ""
};

const offerModel = {
    IdPOferta: 0,
    UsuarioIngreso: "",
    nombreOferta: "",
    DescripcionOferta: "",
    IdFCatServicio: 0,
    IdFEmpresa: 0,
    IdPEmpleado: 0,
    IdFEstado: 0,
    FechaOferta: ""
};

const offerEmployees = {
    IdPEmpleado: []
};

const planningModel = {
    IdPPlanificacion: 0,
    nombrePlanificacion: "",
    fechaRegistro: "",
    fechaInicial: "",
    fechaFinal: "",
    idFEstado: 0,
    DescripcionPlanificacion: "",
    files: [],
    idPOferta: 0
};
const planningProgressModel = {
    idPAvance: 0,
    nombreAvance: "",
    descripcionAvance: "",
    fechaAvance: "",
    estado: true,
    idFPlanificacion: 0,
    usuarioIngreso: "",
    files: []
};

const ReportsModel = [
    {
        reportValue: "Clients",
        reportLabel: "Clientes"
    },
    {
        reportValue: "ActiveClients",
        reportLabel: "Clientes Activos"
    },
    {
        reportValue: "DisabledClientes",
        reportLabel: "Clientes Deshabilitados"
    },
    {
        reportValue: "Offers",
        reportLabel: "Ofertas"
    },
    {
        reportValue: "InProcessOffers",
        reportLabel: "Ofertas en Proceso"
    },
    {
        reportValue: "CancelledOffers",
        reportLabel: "Ofertas Canceladas"
    },
    {
        reportValue: "Planning",
        reportLabel: "Planificaciones"
    },
    {
        reportValue: "InProcessPlanning",
        reportLabel: "Planificaciones en Proceso"
    },
    {
        reportValue: "FinishedPlanning",
        reportLabel: "Planificaciones Finalizadas"
    },
    {
        reportValue: "CancelledPlanning",
        reportLabel: "Planificaciones Canceladas"
    }

];

const contentTypeFiles = [
    "application/pdf",
    "image/png",
    "image/jpeg",
    "image/jpg",
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/vnd.ms-word.document.macroenabled.12",
    "application/msword-template",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.template",
    "application/vnd.ms-word.template.macroenabled.12",
    "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "application/vnd.ms-excel.sheet.macroenabled.12",
    "application/vnd.ms-excel.sheet.binary.macroenabled.12",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.template",
    "application/vnd.ms-excel.template.macroenabled.12",
    "application/vnd.ms-excel.template",
    "application/xml",
    "application/vnd.ms-excel.addin.macroenabled.12",
    "application/vnd.ms-excel.addin ",
    "application/vnd.ms-excel.worksheet",
    "application/vnd.ms-excel.source ",
    "application/octet-stream"
];

export {
    companiesModel,
    usersModel,
    rolesModel,
    statusModel,
    catServicesModel,
    documentsModel,
    empleadoModel,
    offerModel,
    offerEmployees,
    planningModel,
    planningProgressModel,
    userProfileModel,
    ReportsModel,
    contentTypeFiles,
    userChangePassowrd
};
