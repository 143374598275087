import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from '../components/AuthProvider'
import { userProfileModel } from "../emptyModels/emptyModels";
import { Card, CardBody, CardHeader, Button, Modal, ModalHeader, ModalBody, Label, Input, FormGroup, ModalFooter, Row, Col } from "reactstrap"
import Swal from 'sweetalert2'
import jwt_decode from 'jwt-decode';

const Profile = () => {
    const auth = useAuthContext();
    const [user, setUser] = useState(userProfileModel);
    const decodedToken = jwt_decode(auth.user);
    user.userName = decodedToken.userName;
    user.roleName = decodedToken.roleName;
    const navigate = useNavigate();
    
    const handleChange = (e) => {
        let value;

        if (e.target.name == "Estado") {
            value = (e.target.value == "true" ? true : false)
        } else {
            value = e.target.value;
        }
        setUser({
            ...user,
            [e.target.name]: value
        })
    };

    const save = async () => {

        let response;

        response = await fetch("api/account/changepassword", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json;charset=utf-8',
                'Authorization': "Bearer " + auth.user
            },
            body: JSON.stringify(user)
        })

        if (response.ok) {
            navigate('/', { replace: true });
        } else {

            let errorMessage = await response.text();

            Swal.fire(
                'Opp!',
                'No se pudo guardar. ' + errorMessage,
                'warning'
            )
        }
    };

    return (
        <>
            <Card>
                <CardHeader style={{ backgroundColor: '#4e73df', color: "white" }}>
                    Cambio de Password
                </CardHeader>
                <CardBody>
                    <Row>
                        <Col sm={12}>
                            <FormGroup>
                                <Label>Nombre de Usuario:</Label>
                                <Input name="UserName" onChange={handleChange} value={user.userName} disabled/>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <FormGroup>
                                <Label>Rol:</Label>
                                <Input name="RoleName" onChange={handleChange} value={user.roleName} disabled />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <FormGroup>
                                <Label>Password Actual</Label>
                                <Input name="CurrentPassword" type="password" onChange={handleChange} value={user.currentPassword} />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <FormGroup>
                                <Label>Nuevo Password</Label>
                                <Input name="NewPassword" type="password" onChange={handleChange} value={user.newPassword} />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Button size="sm" color="primary" onClick={save}>Guardar</Button>
                    </Row>
                </CardBody>
            </Card>            
        </>
    );
};

export default Profile;