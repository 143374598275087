import React, { useEffect } from 'react';
import jwt_decode from 'jwt-decode';

const TokenValidator = () => {
    useEffect(() => {
        const checkToken = () => {
            const token = localStorage.getItem('token');

            if (token) {
                const decodedToken = jwt_decode(token);
                const currentTime = Date.now() / 1000;

                if (decodedToken.exp < currentTime) {
                    window.location.href = '/login';
                    localStorage.removeItem("token");
                }
            }
        };

        checkToken();

        const intervalId = setInterval(checkToken, 5000); // 10000 milisegundos = 10 segundos

        return () => clearInterval(intervalId);
    }, []);

    return <></>;
};

export default TokenValidator;
