import React, { useRef, useState } from 'react';
import DataTable from 'react-data-table-component';
import { Button } from "reactstrap"
import '../css/inputFile.css'
import { contentTypeFiles } from "../emptyModels/emptyModels.js";
import Swal from 'sweetalert2'

const UploadFiles = ({ files, setFiles, hidden = false }) => {

    const inputFileRef = useRef(null);
    const [idNumber, setIdNumber] = useState(0);

    const customStyles = {
        headCells: {
            style: {
                fontSize: '13px',
                fontWeight: 800,
            },
        },
        headRow: {
            style: {
                backgroundColor: "#eee",
            }
        },
        dataTableContainer: {
            border: '1px solid #ccc',
            borderRadius: '5px',
            padding: '10px',
            margin: '10px',
        }
    };

    const CustomNoDataComponent = () => (
        <div style={{ padding: '10px', textAlign: 'center' }}>
            No hay archivos para mostrar.
        </div>
    );

    const base64toBlob = (base64Data, contentType) => {
        const sliceSize = 512;
        const byteCharacters = atob(base64Data);
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);

            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }

        const blob = new Blob(byteArrays, { type: contentType });
        return blob;
    };

    const openFileInNewTab = (base64Data, fileType, fileName, isPreview) => {
        const blob = base64toBlob(base64Data, fileType);
        const blobUrl = URL.createObjectURL(blob);

        // Crear un enlace de descarga
        const downloadLink = document.createElement('a');
        downloadLink.href = blobUrl;
        downloadLink.download = fileName;

        // Abrir una nueva pestaña y simular un clic en el enlace de descarga
        if (isPreview) {
            window.open(downloadLink, '_blank');
        } else {
            downloadLink.dispatchEvent(new MouseEvent('click'));
        }
    };


    const handleUploadFiles = (event) => {
        
        const uploadedFiles = event.target.files;
        const updatedFiles = [];

        if (contentTypeFiles.includes(uploadedFiles[0].type)) {

            setIdNumber(idNumber + 1)
            for (let i = 0; i < uploadedFiles.length; i++) {
                const file = uploadedFiles[i];
                const reader = new FileReader();

                reader.onloadend = () => {
                    const newFile = {
                        id: idNumber,
                        name: file.name,
                        extension: file.name.split('.').pop(),
                        base64: reader.result.split(',')[1],
                        type: file.type
                    };

                    updatedFiles.push(newFile);

                    if (i === uploadedFiles.length - 1) {
                        setFiles(prevFiles => [...prevFiles, ...updatedFiles]);
                    }
                };

                reader.readAsDataURL(file);
            }
        } else {
            Swal.fire(
                'Opp!',
                `El tipo de archivo: "${uploadedFiles[0].name.split('.').pop()}" no esta permitido.`,
                'error'
            );
        }
    };

    const columns = [
        { name: 'Nombre', selector: row => row.name, },
        { name: 'Extensión', selector: row => row.extension },
        {
            name: 'Acciones',
            cell: row => (
                <>
                    <Button color="primary" size="sm" className="mr-2"
                        onClick={() => openFileInNewTab(row.base64, row.type, row.name, true)} title="Ver" disabled={hidden}
                    >
                        <i className="fa-solid fa-eye"></i>
                    </Button>
                    <Button color="secondary" size="sm" className="mr-2"
                        onClick={() => openFileInNewTab(row.base64, row.type, row.name, false)} title="Descargar" disabled={hidden}
                    >
                        <i className="fa-solid fa-download"></i>
                    </Button>
                    <Button color="danger" size="sm" className="mr-2"
                        onClick={() => handleDeleteFile(row.id)} title="Eliminar" disabled={hidden}
                    >
                        <i className="fas fa-trash-alt"></i>
                    </Button>
                </>
            )
        },
    ];

    const handleDeleteFile = (id) => {

        const updatedFiles = files.filter(file => file.id !== id);
        setFiles(updatedFiles);
        if (inputFileRef.current) {
            inputFileRef.current.value = '';
        }
    };

    return (
        <div>
            <div style={{ marginBottom: '20px' }}>
                <input type="file" onChange={handleUploadFiles}
                    ref={inputFileRef} disabled={hidden}/>
            </div>
            <DataTable
                columns={columns}
                data={files}
                pagination
                highlightOnHover
                customStyles={customStyles}
                noDataComponent={<CustomNoDataComponent />}
            />
        </div>
    );
};

export default UploadFiles;
