import { useRef, useEffect, useState } from 'react';
import { offerModel } from "../emptyModels/emptyModels.js";
import { planningModel } from "../emptyModels/emptyModels.js";
//import Tab from 'react-bootstrap/Tab';
//import Tabs from 'react-bootstrap/Tabs';
import DataTable from 'react-data-table-component';
import { Card, CardBody, CardHeader, Button, Modal, ModalHeader, ModalBody, Label, Input, FormGroup, ModalFooter, Row, Col } from "reactstrap"
import Datetime from 'react-datetime'
import moment from 'moment'
import { Select, Tabs } from 'antd';
import Swal from 'sweetalert2'
import UploadFiles from '../components/UploadFiles'
import { useAuthContext } from '../components/AuthProvider'
import PlanningProgress from './PlanningProgress'

const { Option } = Select;
const { TabPane } = Tabs;


const Planning = () => {
    const [planning, setPlanning] = useState(planningModel);
    const [plannings, setPlannings] = useState([]);
    const [offer, setOffer] = useState(offerModel);
    const [offers, setOffers] = useState([]);
    const [filteredOfertas, setFilteredOfertas] = useState([]);   
    const [filteredPlannings, setFilteredPlannings] = useState([]);
    const [verModal, setVerModal] = useState(false);
    const [statuses, setStatuses] = useState([]);
    const auth = useAuthContext();
    const [files, setFiles] = useState([]);
    const [verProgressModal, setVerProgressModal] = useState(false);
    const [planningProgresses, setPlanningProgresses] = useState([]);
    const [search, setSearch] = useState('');

    useEffect(() => {
        getOffers();
        getPlannings();
        const initializeData = async () => {
            try {
                const [statusesResponse] = await Promise.all([
                    fetch("api/planning/GetStatuses", {
                        method: 'GET',
                        headers: {
                            "Authorization": "Bearer " + auth.user,
                        }
                    }),
                ]);
                const [statusesData] = await Promise.all([
                    statusesResponse.json(),
                ]);

                setStatuses(statusesData.filter(item => item.estado1 === true));
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        initializeData();
    }, []);

    useEffect(() => {
        const result = plannings.filter((item) => {
            return (
                (item.nombrePlanificacion && item.nombrePlanificacion.toLowerCase().includes(search.toLowerCase())) ||
                (item.descripcionPlanificacion && item.descripcionPlanificacion.toLowerCase().includes(search.toLowerCase())) ||
                (item.nombreOferta && item.nombreOferta.toLowerCase().includes(search.toLowerCase()))
            );
        });
        setFilteredPlannings(result);
    }, [search]);

    const closeModal = () => {
        setOffer(offerModel);
        setFiles([]);
        setVerModal(!verModal);
    }

    const getDocumentsPlanning = async (IdPPlanificacion) => {
        let response = await fetch("api/planning/GetDocuments/" + IdPPlanificacion, {
            method: 'GET',
            headers: {
                "Authorization": "Bearer " + auth.user,
            }
        });

        if (response.ok) {
            let data = await response.json()
            setFiles(data)
        }
    }
   
    const getPlanProgresses= async (planning) => {
        
        let response = await fetch("api/planning/getPlanProgresses/" + planning.idPPlanificacion, {
            method: 'GET',
            headers: {
                "Authorization": "Bearer " + auth.user,
            }
        });
        if (response.ok) {
            let data = await response.json()
            setPlanningProgresses(data);           
        }
    }
    const savePlanning = async () => {
        let response;

        planning.files = files;
        planning.IdPOferta = offer.idPOferta;

        if (planning.IdPPlanificacion == 0) {
            response = await fetch("api/planning/save", {
                method: 'POST',
                headers: {
                    "Authorization": "Bearer " + auth.user,
                    'Content-Type': 'application/json;charset=utf-8'
                },
                body: JSON.stringify(planning)
            })

        }
        else {
            response = await fetch("api/planning/update", {
                method: 'POST',
                headers: {
                    "Authorization": "Bearer " + auth.user,
                    'Content-Type': 'application/json;charset=utf-8'
                },
                body: JSON.stringify(planning)
            })
        }

        if (response.ok) {
            await getOffers();
            await getPlannings();
            setPlanning(planningModel);
            setVerModal(!verModal);
        }
        else if (response.status == 409 || response.status == 401) {
            Swal.fire(
                'Opp!',
                'No se pudo guardar: ' + await response.text(),
                'warning'
            )

        }     
        else {

            Swal.fire(
                'Opp!',
                'No se pudo guardar.',
                'warning'
            )
        }
    }

    function onChangeStatus(value) {
        setPlanning({
            ...planning,
            ["idFEstado"]: value
        })
    }

    function onChangeFechaRegistro(dateString) {
        setPlanning({
            ...planning,
            ["FechaRegistro"]: moment(new Date(dateString._d)).format('DD/MM/YYYY')
        })
    }

    function onChangeFechaInicial(dateString) {
        setPlanning({
            ...planning,
            ["FechaInicial"]: moment(new Date(dateString._d)).format('DD/MM/YYYY')
        })
    }

    function onChangeFechaFinal(dateString) {
        setPlanning({
            ...planning,
            ["FechaFinal"]: moment(new Date(dateString._d)).format('DD/MM/YYYY')
        });
    }
    const CustomNoDataComponent = () => (
        <div style={{ padding: '10px', textAlign: 'center' }}>
            No hay registros de planificaciones.
        </div>
    );

    const handleChange = (e) => {

        let value;
        value = e.target.value;

        setPlanning({
            ...planning,
            [e.target.name]: value
        })
    };

    const openEditModal = (data) => {
        setPlanning(planningModel);
        setOffer(data);
        setVerModal(!verModal);
    }


    const openProgressEditModal = (data) => {
        
        setPlanning(data);
        setVerProgressModal(!verProgressModal);
        getPlanProgresses(data)
    }

    const openEditPlanningModal = (data) => {
        setPlanning(data);
        getDocumentsPlanning(data.idPPlanificacion);
        setOffer(data);
        setVerModal(!verModal);
    }

    /* Styles Table options */
    const customStyles = {
        headCells: {
            style: {
                fontSize: '13px',
                fontWeight: 800,
            },
        },
        headRow: {
            style: {
                backgroundColor: "#eee",
            }
        }
    };

    const offerColumns = [
        { name: 'Nombre Oferta', selector: row => row.nombreOferta, sortable: true },
        { name: 'Descripción Oferta', selector: row => row.descripcionOferta, sortable: true },
        { name: 'Servicio', selector: row => row.idFCatServicioNavigation.nombreCatServicio, sortable: true },
        { name: 'Cliente', selector: row => row.idFEmpresaNavigation.nombreEmpresa, sortable: true },
        {
            name: 'Fecha Oferta', selector: row => {
                const formattedDate = new Date(row.fechaOferta).toLocaleDateString('es-ES', {
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric'
                });
                return formattedDate;
            }, sortable: true
        },
        { name: 'Estado Oferta', selector: row => row.idFEstadoNavigation.nombreEstado, sortable: true },
        {
            name: 'Colaborador Asignado',
            selector: row => {
                if (row.detalleOferta && row.detalleOferta[0] && row.detalleOferta[0].idPEmpleado) {
                    return row.detalleOferta.map(detalle => detalle.nombreEmpleado).join(', ');
                }
                return '';
            },
            sortable: true
        }, {
            name: '',
            cell: row => (
                <>
                    <Button color="primary" size="sm" className="mr-2"
                        onClick={() => openEditModal(row)} title="Planificar Oferta"
                    >
                        <i className="fa-regular fa-calendar-check"></i>
                    </Button>                    
                </>
            ),
        },
    ];

    const planningColumns = [
        { name: 'Nombre', selector: row => row.nombrePlanificacion, sortable: true },
        { name: 'Descripcion', selector: row => row.descripcionPlanificacion, sortable: true },
        { name: 'Oferta', selector: row => row.nombreOferta, sortable: true },
        { name: 'Estado', selector: row => row.nombreEstado, sortable: true },        
        {
            name: 'Fecha', selector: row => {
                const formattedDate = new Date(row.fechaRegistro).toLocaleDateString('es-ES', {
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric'
                });
                return formattedDate;
            }, sortable: true
        },
        {
            name: 'Fecha Inicial', selector: row => {
                const formattedDate = new Date(row.fechaInicial).toLocaleDateString('es-ES', {
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric'
                });
                return formattedDate;
            }, sortable: true
        },
        {
            name: 'Fecha Final', selector: row => {
                const formattedDate = new Date(row.fechaFinal).toLocaleDateString('es-ES', {
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric'
                });
                return formattedDate;
            }, sortable: true
        },
        {
            name: '',
            cell: row => (
                <>                    
                            <Button color="primary" size="sm" className="mr-2"
                                onClick={() => openEditPlanningModal(row)} title="Editar Planificación">
                                <i className="fas fa-pen-alt"></i>
                            </Button>
                            <Button color="success" size="sm" className="mr-2"
                                onClick={() => openProgressEditModal(row)} title="Agregar Avance"
                            >
                                <i className="fa-solid fa-forward"></i>
                            </Button>
                </>
            ),
        }
    ];

    /* Get List Offers */
    const getOffers = async () => {
        let response = await fetch("api/offers/GetOffersToPlanning", {
            method: 'GET',
            headers: {
                "Authorization": "Bearer " + auth.user,
            }
        });

        if (response.ok) {
            let data = await response.json()
            setOffers(data);
            setFilteredOfertas(data);
        }
    }

    const getPlannings = async () => {
        let response = await fetch("api/planning/", {
            method: 'GET',
            headers: {
                "Authorization": "Bearer " + auth.user,
            }
        });

        if (response.ok) {
            let data = await response.json()
            setPlannings(data);
            setFilteredPlannings(data);
        }
    };

    return (
        <Tabs
            defaultActiveKey="finishedOffers"
            id="uncontrolled-tab-example"
            className="mb-3"
            size={"large"}
        >
            <TabPane key="finishedOffers" tab="Ofertas">
                <DataTable
                    title={'Todas las ofertas'}
                    columns={offerColumns}
                    data={filteredOfertas}
                    pagination
                    highlightOnHover
                    customStyles={customStyles}
                    onRowClicked={(row) => console.log(row)}
                />

                <Modal isOpen={verModal} size="xl" centered={true} >
                    <ModalHeader>
                        Detalle Planificacion
                    </ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col sm={6}>
                                <FormGroup>
                                    <Label>Nombre Planificacion</Label>
                                    <Input name="nombrePlanificacion" onChange={handleChange} value={planning.nombrePlanificacion} />
                                </FormGroup>
                            </Col>
                            <Col sm={6}>
                                <FormGroup>
                                    <Label>Oferta</Label>
                                    <Input name="nombreOferta" onChange={handleChange} value={offer.nombreOferta} disabled />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={6}>
                                <FormGroup>
                                    <Label>Fecha Registro</Label>
                                    <Datetime
                                        placeholder="Seleccionar Fecha"
                                        name="fechaRegistro"
                                        input="true"
                                        onChange={onChangeFechaRegistro}
                                        dateFormat="DD/MM/YYYY"
                                        value={planning.IdPPlanificacion === 0 ? "Seleccionar" : moment(planning.fechaRegistro).format('DD/MM/YYYY')}
                                        timeFormat={false}
                                        closeOnSelect={true} />
                                </FormGroup>
                            </Col>
                            <Col sm={6}>
                                <FormGroup>
                                    <Label>Fecha Inicio</Label>
                                    <Datetime
                                        placeholder="Seleccionar Fecha"
                                        name="fechaInicial"
                                        input="true"
                                        onChange={onChangeFechaInicial}
                                        dateFormat="DD/MM/YYYY"
                                        value={planning.IdPPlanificacion === 0 ? "Seleccionar" : moment(planning.fechaInicial).format('DD/MM/YYYY')}
                                        timeFormat={false}
                                        closeOnSelect={true} />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={6}>
                                <FormGroup>
                                    <Label>Fecha Final</Label>
                                    <Datetime
                                        placeholder="Seleccionar Fecha"
                                        name="fechaFinal"
                                        input="true"
                                        onChange={onChangeFechaFinal}
                                        dateFormat="DD/MM/YYYY"
                                        value={planning.IdPPlanificacion === 0 ? "Seleccionar" : moment(planning.fechaFinal).format('DD/MM/YYYY')}
                                        timeFormat={false}
                                        closeOnSelect={true} />
                                </FormGroup>
                            </Col>
                            <Col xs={6}>
                                <FormGroup>
                                    <Label>Estado</Label>
                                    <Select
                                        showSearch
                                        size="large"
                                        notFoundContent="Sin elementos"
                                        style={{ width: '100%' }}
                                        optionFilterProp="children"
                                        placeholder="Seleccionar Estado..."
                                        onChange={onChangeStatus}
                                        filterOption={(input, option) =>
                                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        defaultValue={planning.IdPPlanificacion === 0 ? undefined : { key: planning.idFEstado }}
                                    >
                                        <Option value={0}>Buscar...</Option>
                                        {
                                            statuses.map((item) => {
                                                return (<Option key={item.idPEstado} value={item.idPEstado}>{item.nombreEstado}</Option>)
                                            })
                                        }
                                    </Select>
                                </FormGroup>
                            </Col>

                        </Row>
                        <Row>
                            <Col xs={12}>
                                <FormGroup>
                                    <Label>Descripción</Label>
                                    <textarea className="form-control"
                                        name="DescripcionPlanificacion"
                                        onChange={handleChange}
                                        value={planning.descripcionPlanificacion}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12}>
                                <FormGroup>
                                    <Label>Cargar Archivo</Label>
                                    <UploadFiles files={files} setFiles={setFiles} />
                                </FormGroup>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button size="sm" color="primary" onClick={savePlanning}>Guardar</Button>
                        <Button size="sm" color="danger" onClick={closeModal}>Cerrar</Button>
                    </ModalFooter>
                </Modal>
            </TabPane>
            <TabPane key="planningList" tab="Planificaciones">
                <DataTable
                    title={'Planificaciones'}
                    columns={planningColumns}
                    data={filteredPlannings}
                    pagination
                    highlightOnHover
                    customStyles={customStyles}
                    onRowClicked={(row) => console.log(row)}
                    subHeader
                    subHeaderComponent={
                        <input type="text"
                            className="w-25 form-control"
                            placeholder="Buscar..."
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                        />
                    }
                    subHeaderAlign="right"
                    noDataComponent={<CustomNoDataComponent />}
                />

                
                <PlanningProgress verProgressModal={verProgressModal} setVerProgressModal={setVerProgressModal} planning={planning} planningProgresses={planningProgresses} setPlanningProgresses={setPlanningProgresses} />
            </TabPane>
        </Tabs>
    );
}

export default Planning;
