import { useRef, useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { Card, CardBody, CardHeader, Button, Modal, ModalHeader, ModalBody, Label, Input, FormGroup, ModalFooter, Row, Col } from "reactstrap"
import { catServicesModel } from "../emptyModels/emptyModels.js";
import Swal from 'sweetalert2'
import { catServiceRequiredModel } from "../requiredModels/requiredModels.js";
import validateFields from "../requiredModels/useValidationFields.js";
import { useAuthContext } from '../components/AuthProvider'

const CatServices = () => {

    const [catservices, setCatServices] = useState(catServicesModel);
    const [catservices1, setCatServices1] = useState([]);
    const [pendiente, setPendiente] = useState(true);
    const [verModal, setVerModal] = useState(false);
    const [search, setSearch] = useState('');
    const [filter, setFilter] = useState([]);
    const auth = useAuthContext();


    useEffect(() => {
        getCatServices();
    }, [])

    useEffect(() => {
        const result = catservices1.filter((item) => {
            return (
                (item.nombreCatServicio && item.nombreCatServicio.toLowerCase().includes(search.toLowerCase())) ||
                (item.descripcionCatServicio && item.descripcionCatServicio.toLowerCase().includes(search.toLowerCase())) ||
                ((item.estado ? "Activo" : "Deshabilitado").toLowerCase().includes(search.toLowerCase())) ||
                (item.fechaIngreso && item.fechaIngreso.toLowerCase().includes(search.toLowerCase()))               
            );            
        });
        setFilter(result);
    }, [search]);

    /* Columns Data CatServices */

    const columns = [
        {
            name: 'Servicio',
            selector: row => row.nombreCatServicio,
            sortable: true,
        },
        {
            name: 'Descripcion',
            selector: row => row.descripcionCatServicio,
            sortable: true,
        },
        {
            name: 'Fecha de Ingreso', selector: row => {
                const formattedDate = new Date(row.fechaCatServicio).toLocaleDateString('es-ES', {
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric'
                });
                return formattedDate;
            }, sortable: true
        },
       
        {
            name: 'Estado',
            selector: row => row.estado,
            sortable: true,
            cell: row => {
                let clase;
                clase = row.estado ? "badge badge-info p-2" : "badge badge-danger p-2"
                return (
                    <span style={{ width: '100px' }}  className={clase}>{row.estado ? "Activo" : "Deshabilitado"}</span>
                )
            }
        }, {
            name: '',
            cell: row => (
                <>
                    <Button color="primary" size="sm" className="mr-2"
                        onClick={() => openEditModal(row)}
                    >
                        <i className="fas fa-pen-alt"></i>
                    </Button>
                </>
            ),
        },
    ];
    /* Paginations table options */
    const paginationComponentOptions = {
        rowsPerPageText: 'Filas por pagina',
        rangeSeparatorText: 'de',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Todos',
    };

    /* Styles Table options */

    const customStyles = {
        headCells: {
            style: {
                fontSize: '13px',
                fontWeight: 800,
            },
        },
        headRow: {
            style: {
                backgroundColor: "#eee",
            }
        }
    };

    /* Auxiliary Methods */

    const handleChange = (e) => {       
        let value;

        if (e.target.name == "estado") {
            value = (e.target.value == "true" ? true : false)
        } else {
            value = e.target.value;
        }
        setCatServices({
            ...catservices,
            [e.target.name]: value
        })
    }
    const closeModal = () => {
        setCatServices(catServicesModel)
        setVerModal(!verModal);
    }

    const openEditModal = (data) => {
        
        setCatServices(data);
        console.log(catservices)
        setVerModal(!verModal);
    }
    const CustomNoDataComponent = () => (
        <div style={{ padding: '10px', textAlign: 'center' }}>
            No hay registros de servicios.
        </div>
    );
    /*CRUD METHODS*/

    /* Get List CatServices */
    const getCatServices = async () => {
        let response = await fetch("api/catservices/GetCatServices", {
            method: 'GET',
            headers: {
                "Authorization": "Bearer " + auth.user,
            }
        });
      
        if (response.ok) {
            let data = await response.json()
            setCatServices1(data)
            setFilter(data);
            setPendiente(false)
        }
    }
    /* Save Services */
    const saveCatServices = async () => {

        /*Campos Requeridos*/

        const validationResult = validateFields(catServiceRequiredModel, catservices);

        if (validationResult.requiredFields) {

            Swal.fire(
                'Opp!',
                `Por favor, complete los campos requeridos: ${validationResult.missingFields}.`,
                'error'
            );
            return;
        }

        let response;

        if (catservices.idPCatServicio == 0) {
            response = await fetch("api/catservices/SaveCatService", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8',
                    "Authorization": "Bearer " + auth.user
                },
                body: JSON.stringify(catservices)
            })

        } else {
            response = await fetch("api/catservices/EditCatService", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8',
                    "Authorization": "Bearer " + auth.user
                },
                body: JSON.stringify(catservices)
            })
        }

        if (response.ok) {
            await getCatServices();
            setCatServices(catServicesModel)
            setVerModal(!verModal);
            setSearch('')
        }
        else if (response.status == 409) {
            Swal.fire(
                'Opp!',
                'No se pudo guardar: ' + await response.text(),
                'warning'
            )

        } else {

            Swal.fire(
                'Opp!',
                'No se pudo guardar.',
                'warning'
            )
        }
    }
    /* Delete catServices */
    const deleteCatServices = async (id) => {
        
        Swal.fire({
            title: 'Esta seguro?',
            text: "Desea eliminar el servicio",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si, continuar',
            cancelButtonText: 'No, volver'
        }).then((result) => {
            if (result.isConfirmed) {

            }
        })
    }

    return (
        <>
            <Card>
                <CardHeader style={{ backgroundColor: '#4e73df', color: "white" }}>
                    Lista de Servicios
                </CardHeader>
                <CardBody>
                    <Button color="success" size="sm" onClick={() => setVerModal(!verModal)}>Nuevo Servicio</Button>
                    <hr></hr>
                    <DataTable
                        columns={columns}
                        data={filter}
                        progressPending={pendiente}
                        pagination
                        paginationComponentOptions={paginationComponentOptions}
                        customStyles={customStyles}
                        subHeader
                        subHeaderComponent={
                            <input type="text"
                                className="w-25 form-control"
                                placeholder="Buscar..."
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}

                            />
                        }
                        subHeaderAlign="right"
                        noDataComponent={<CustomNoDataComponent />}
                    />
                </CardBody>
            </Card>

            <Modal isOpen={verModal}>
                <ModalHeader>
                    Detalle de Servicio
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <Col sm={6}>
                            <FormGroup>
                                <Label>Nombre Servicio</Label>
                                <span style={{ color: 'red' }}>*</span>
                                <Input name="nombreCatServicio" onChange={handleChange} value={catservices.nombreCatServicio} />
                            </FormGroup>
                        </Col>
                        <Col sm={6} >
                            <FormGroup>
                                <Label>Estado</Label>
                                <Input className="form-control" type={"select"} name="estado" onChange={handleChange} value={catservices.estado} >
                                    <option value={true}>Activo</option>
                                    <option value={false}>Deshabilitado</option>
                                </Input>
                            </FormGroup>
                        </Col>
                        
                    </Row>
                    <Row>                       
                        <Col sm={12}>
                            <FormGroup>
                                <Label>Descripcion</Label>
                                <textarea className="form-control" name="descripcionCatServicio" onChange={handleChange} value={catservices.descripcionCatServicio} />
                            </FormGroup>
                        </Col> 
                    </Row>
    
                </ModalBody>
                <ModalFooter>
                    <Button size="sm" color="primary" onClick={saveCatServices}>Guardar</Button>
                    <Button size="sm" color="danger" onClick={closeModal}>Cerrar</Button>
                </ModalFooter>
            </Modal>
        </>
    );
}

export default CatServices;

