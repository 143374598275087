import React, { useContext, useState } from 'react'
import NavBar from './NavBar'
import { Link } from 'react-router-dom';
import { useAuth } from "./useAuth";
import Swal from 'sweetalert2'
import { useNavigate, Outlet } from 'react-router-dom';
import { useAuthContext } from '../components/AuthProvider'
import jwt_decode from 'jwt-decode';


const Layout = () => {

    const auth = useAuthContext(); 
    const { signOut } = useAuth(); 
    const navigate = useNavigate();
    const decodedToken = jwt_decode(auth.user);
    const [userName, setUserName] = useState(decodedToken.userName);

    const showExitModal = () => {

        Swal.fire({
            title: 'Esta por salir',
            text: "Desea cerrar sesion?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si, continuar',
            cancelButtonText: 'No, volver'
        }).then((result) => {
            if (result.isConfirmed) {
                signOut();
                navigate("/Login");
                window.location.reload();
            }
        })

    }

    return (
        <>

            <NavBar />

            {/*Content Wrapper*/}

            <div id="content-wrapper" className="d-flex flex-column">

                {/*Main Content*/}
                <div id="content">
                    <nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">

                        {/* Sidebar Toggle (Topbar) */}
                        <button id="sidebarToggleTop" className="btn btn-link d-md-none rounded-circle mr-3">
                            <i className="fa fa-bars"></i>
                        </button>

                        {/* Topbar Navbar */}
                        <ul className="navbar-nav ml-auto">

                            <div className="topbar-divider d-none d-sm-block"></div>

                            {/* Nav Item - User Information */}
                            <li className="nav-item dropdown no-arrow">
                                <a className="nav-link dropdown-toggle" href="#" id="userDropdown" role="button"
                                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <span className="mr-2 d-none d-lg-inline text-gray-600 small">{userName}</span>
                                    <img className="img-profile rounded-circle"
                                        src={"./imagen/user.jpg"} />
                                </a>
                                {/* Dropdown - User Information */}
                                <div className="dropdown-menu dropdown-menu-right shadow animated--grow-in"
                                    aria-labelledby="userDropdown">
                                    <Link className="dropdown-item" to="/Profile">
                                        <i className="fas fa-user fa-sm fa-fw mr-2 text-gray-400"></i>
                                        Perfil
                                    </Link>
                                    <div className="dropdown-divider"></div>
                                    <button className="dropdown-item" onClick={showExitModal}>
                                        <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
                                        Salir
                                    </button>
                                </div>
                            </li>

                        </ul>

                    </nav>
                    <div className="container-fluid">

                        <Outlet />

                    </div>
                </div>
                <footer className="sticky-footer bg-white">
                    <div className="container my-auto">
                        <div className="copyright text-center my-auto">
                            <span>Copyright &copy; Auditores 2023</span>
                        </div>
                    </div>
                </footer>
            </div>

        </>
    )
}

export default Layout