import React, { useState, useEffect } from 'react';
import { Button } from "reactstrap"
import { Chart } from 'react-google-charts';
import DataTable from 'react-data-table-component';
import { Spin } from 'antd';
import { offerModel, offerEmployees } from "../emptyModels/emptyModels.js";
import { useAuthContext } from '../components/AuthProvider'

const OfertasComponent = ({ offers, setOffer, setFiles, setOfferEmployees, verModal, setVerModal, setHidden, hidden, search, setSearch, filter, setFilter }) => {
    
    const [selectedEstado, setSelectedEstado] = useState(null);
    const [dataForChart, setDataForChart] = useState(null);
    const [filteredOfertas, setFilteredOfertas] = useState([]);   
    const [isHovered, setIsHovered] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const auth = useAuthContext();

    const handleMouseOver = () => {
        setIsHovered(true);
    };

    const handleMouseOut = () => {
        setIsHovered(false);
    };

    useEffect(() => {
        getPieData();       
    }, [offers])

    useEffect(() => {
        const result = filteredOfertas.filter((item) => {
            return (
                (item.nombreOferta && item.nombreOferta.toLowerCase().includes(search.toLowerCase())) ||
                (item.descripcionOferta && item.descripcionOferta.toLowerCase().includes(search.toLowerCase())) ||
                (item.idFCatServicioNavigation.nombreCatServicio && item.idFCatServicioNavigation.nombreCatServicio.toLowerCase().includes(search.toLowerCase())) ||
                (item.idFEmpresaNavigation.nombreEmpresa && item.idFEmpresaNavigation.nombreEmpresa.toLowerCase().includes(search.toLowerCase())) ||
                (item.fechaOferta && item.fechaOferta.toLowerCase().includes(search.toLowerCase())) ||
                (item.idFEstadoNavigation.nombreEstado && item.idFEstadoNavigation.nombreEstado.toLowerCase().includes(search.toLowerCase())) ||
                (item.detalleOferta.some(detalle => detalle.nombreEmpleado.toLowerCase().includes(search.toLowerCase())))
            );
        });
        setFilter(result);
    }, [search, filteredOfertas]);

    useEffect(() => {
        const filteredOfertas = selectedEstado
            ? offers.filter(oferta => oferta.idFEstadoNavigation.nombreEstado === selectedEstado)
            : offers;
        setFilteredOfertas(filteredOfertas);       
    }, [selectedEstado,offers])

    const getPieData = () => {
        
        const estados = Array.from(new Set(offers.map(oferta => oferta.idFEstadoNavigation.nombreEstado)));
        const estadoOfertas = estados.reduce((acc, estado) => {
            acc[estado] = offers.filter(oferta => oferta.idFEstadoNavigation.nombreEstado === estado).length;
            return acc;
        }, {});

        const dataForChart = [['Estado', 'Número de Ofertas']].concat(
            Object.entries(estadoOfertas).map(([estado, numOfertas]) => [estado, numOfertas])
        );
        
        setDataForChart(dataForChart)
        setIsLoading(false);
    }

    const columns = [
        { name: 'Nombre Oferta', selector: row => row.nombreOferta, sortable: true },
        { name: 'Descripción Oferta', selector: row => row.descripcionOferta, sortable: true },
        { name: 'Servicio', selector: row => row.idFCatServicioNavigation.nombreCatServicio, sortable: true },
        { name: 'Cliente', selector: row => row.idFEmpresaNavigation.nombreEmpresa, sortable: true },
        {
            name: 'Fecha Oferta', selector: row => {
                const formattedDate = new Date(row.fechaOferta).toLocaleDateString('es-ES', {
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric'
                });
                return formattedDate;
            }, sortable: true
        },
        { name: 'Estado Oferta', selector: row => row.idFEstadoNavigation.nombreEstado, sortable: true },
        {
            name: 'Colaborador Asignado',
            selector: row => {
                if (row.detalleOferta && row.detalleOferta[0] && row.detalleOferta[0].idPEmpleado) {
                    return row.detalleOferta.map(detalle => detalle.nombreEmpleado).join(', ');
                }
                return '';
            },
            sortable: true
        }, {
            name: '',
            cell: row => (
                <>
                    <Button color="primary" size="sm" className="mr-2"
                        onClick={() => openEditModal(row)}
                    >
                        <i className="fas fa-pen-alt"></i>
                    </Button>
                </>
            ),
        },
    ];

    const openEditModal = (data) => {
        
        getDocumentsOffers(data.idPOferta)
        getEmployessOffers(data.detalleOferta)
        getOffer(data)
        setVerModal(!verModal);
        // Analizar Estado de Oferta      
        const status = data.idFEstadoNavigation.nombreEstado
        if (status === "Finalizado") {
            setHidden(true)
        }
    }

    const getDocumentsOffers = async (IdPOferta) => {
        let response = await fetch("api/offers/GetDocumentsOffer/" + IdPOferta, {
            method: 'GET',
            headers: {
                "Authorization": "Bearer " + auth.user
            }
        });

        if (response.ok) {           
            let data = await response.json()
            setFiles(data)
        }
    }    
    const getEmployessOffers = async (DetalleOferta) => {

        offerEmployees.IdPEmpleado = DetalleOferta.map(detalle => detalle.idPEmpleado);
        setOfferEmployees(offerEmployees)
    }
    const getOffer = async (data) => {
        
        offerModel.IdPOferta = data.idPOferta;
        offerModel.UsuarioIngreso = data.usuarioIngreso;
        offerModel.NombreOferta = data.nombreOferta;
        offerModel.DescripcionOferta = data.descripcionOferta;
        offerModel.IdFCatServicio = data.idFCatServicio;
        offerModel.IdFEmpresa = data.idFEmpresa;
        offerModel.IdFEstado = data.idFEstado;
        offerModel.FechaOferta = data.fechaOferta;
        setOffer(offerModel)
    }

    /* Styles Table options */
    const customStyles = {
        headCells: {
            style: {
                fontSize: '13px',
                fontWeight: 800,
            },
        },
        headRow: {
            style: {
                backgroundColor: "#eee",
            }
        }
    };

    const handleChartSelect = ({ chartWrapper }) => {

        const chart = chartWrapper.getChart();
        const selection = chart.getSelection();
        if (selection.length === 1) {
            const estado = dataForChart[selection[0].row + 1][0];
            setSelectedEstado(estado);
        }
    };


    return (        
        <div> 
            <Spin spinning={isLoading} size="large" tip="Cargando...">

            <div className={`chart-container ${isHovered ? 'chart-container-hovered' : ''}`}
                    onMouseOver={handleMouseOver}
                    onMouseOut={handleMouseOut}>
                    <Chart
                        width={'100%'}
                        height={'400px'}
                        chartType="PieChart"
                        loader={<div>Cargando gráfico</div>}
                        data={dataForChart}
                        options={{
                            title: 'Distribución de Ofertas por Estado',
                        }}
                        chartEvents={[
                            {
                                eventName: 'select',
                                callback: handleChartSelect
                            },
                        ]}
                    /> </div>
                {selectedEstado && (
                    <div style={{ marginTop: '10px' }}>
                        <Button color="primary" onClick={() => setSelectedEstado(null)}>
                            Ver Todo
                        </Button>
                    </div>
                )}
                <div style={{ marginTop: '20px' }}>
                    <DataTable
                        title={`Ofertas en: ${selectedEstado ? `${selectedEstado}` : 'Todas las ofertas'}`}
                        columns={columns}
                        data={filter}
                        pagination
                        highlightOnHover
                        customStyles={customStyles}
                        onRowClicked={(row) => console.log(row)}
                        subHeader
                        subHeaderComponent={
                            <input type="text"
                                className="w-25 form-control"
                                placeholder="Buscar..."
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}

                            />
                        }
                        subHeaderAlign="right"
                    />
                </div>
            </Spin>                          
        </div>
    );
};

export default OfertasComponent;
