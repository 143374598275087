import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App"
import { AuthProvider } from "./components/AuthProvider";

ReactDOM.createRoot(document.getElementById('wrapper')).render(
    <React.StrictMode>
        <AuthProvider>            
            <App />
        </AuthProvider>
    </React.StrictMode>
)