import React, { useContext,useEffect,useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { useAuthContext } from '../components/AuthProvider'
import jwt_decode from 'jwt-decode';


const NavBar = () => {

    const auth = useAuthContext();    
    const [menus, setMenus] = useState(null);

    useEffect( () => {
        initMenus();
    }, [])    

    function initMenus() {
        
        const decodedToken = jwt_decode(auth.user);
        const userviews = JSON.parse(decodedToken.userAccess);
      
        userviews.sort((a, b) => a.OrderItem - b.OrderItem);
        setMenus(            
            userviews.map((item, key) => {
                return getMenus(item, key);
                })           
        )
    }

    function getMenus(item, key)
    {
        
        if (item.UserAccessDetails.CanView) {           
            return (
                <li key={key} className="nav-item">
                    <NavLink to={`/${item.NavLink}`} className="nav-link" >
                        <i className={item.Icon} ></i>
                        <span>{item.Label}</span>
                    </NavLink>
                </li>            
            );
        }
        return null
    }

    return (

        <ul className="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion" id="accordionSidebar">

            <Link className="sidebar-brand d-flex align-items-center justify-content-center" to="/">
                <div>
                    <img className="logo-icon"  src={"./imagen/logoauditorpng.png"} />
                </div>               
            </Link>

            <hr className="sidebar-divider my-0" />

            <li className="nav-item">
                <NavLink to="/" className="nav-link" >
                    <i className="fa fa-home"></i>
                    <span>Inicio</span>
                </NavLink>
            </li>

            <hr className="sidebar-divider" />

            {/*Menus din�micos*/}

            {menus}

            {/*Menus din�micos*/}

            <hr className="sidebar-divider d-none d-md-block" />

            <div className="text-center d-none d-md-inline">
                <button className="rounded-circle border-0" id="sidebarToggle"></button>
            </div>

        </ul>
    )
}

export default NavBar;