const companiesColumns = [
    {
        id: 'nombreEmpresa',
        name: 'Nombre',
        selector: row => row.nombreEmpresa,
        sortable: true,
    },
    {
        id: 'ruc',
        name: 'Ruc',
        selector: row => row.ruc,
        sortable: true,
    },
    {
        id: 'descripcionEmpresa',
        name: 'Información',
        selector: row => row.descripcionEmpresa,
        sortable: true,
    },
    {
        id: 'direccion',
        name: 'Dirección',
        selector: row => row.direccion,
        sortable: true,
    },
    {
        id: 'fechaIngreso',
        name: 'Fecha de Ingreso',
        selector: row => row.fechaIngreso,
        sortable: true,
    },
    {
        id: 'estado',
        name: 'Estado',
        selector: row => row.estado,
        sortable: true,
        cell: row => {
            let clase;
            clase = row.estado ? "badge badge-info p-2" : "badge badge-danger p-2"
            return (
                <span className={clase}>{row.estado ? "Activo" : "Deshabilitado"}</span>
            )
        }
    }
];

const offersColumns = [
    { id: 'nombreOferta', name: 'Nombre Oferta', selector: row => row.nombreOferta, sortable: true },
    { id: 'descripcionOferta', name: 'Descripción Oferta', selector: row => row.descripcionOferta, sortable: true },
    { id: 'idFCatServicioNavigation.nombreCatServicio', name: 'Servicio', selector: row => row.idFCatServicioNavigation.nombreCatServicio, sortable: true },
    { id: 'idFEmpresaNavigation.nombreEmpresa', name: 'Cliente', selector: row => row.idFEmpresaNavigation.nombreEmpresa, sortable: true },
    {
        id: 'fechaOferta', name: 'Fecha Oferta', selector: row => {
            const formattedDate = new Date(row.fechaOferta).toLocaleDateString('es-ES', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric'
            });
            return formattedDate;
        }, sortable: true
    },
    { id: 'idFEstadoNavigation.nombreEstado', name: 'Estado Oferta', selector: row => row.idFEstadoNavigation.nombreEstado, sortable: true },
    {
        id: 'detalleOferta.nombreEmpleado',
        name: 'Colaborador Asignado',
        selector: row => {
            if (row.detalleOferta && row.detalleOferta[0] && row.detalleOferta[0].idPEmpleado) {
                return row.detalleOferta.map(detalle => detalle.nombreEmpleado).join(', ');
            }
            return '';
        },
        sortable: true
    }
];

const planningColumns = [
    { id:'nombrePlanificacion', name: 'Nombre', selector: row => row.nombrePlanificacion, sortable: true },
    { id: 'descripcionPlanificacion', name: 'Descripcion', selector: row => row.descripcionPlanificacion, sortable: true },
    { id: 'nombreOferta', name: 'Oferta', selector: row => row.nombreOferta, sortable: true },
    { id: 'nombreEstado', name: 'Estado', selector: row => row.nombreEstado, sortable: true },
    {
        id: 'fechaRegistro', name: 'Fecha', selector: row => {
            const formattedDate = new Date(row.fechaRegistro).toLocaleDateString('es-ES', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric'
            });
            return formattedDate;
        }, sortable: true
    },
    {
        id: 'fechaInicial', name: 'Fecha Inicial', selector: row => {
            const formattedDate = new Date(row.fechaInicial).toLocaleDateString('es-ES', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric'
            });
            return formattedDate;
        }, sortable: true
    },
    {
        id: 'fechaFinal', name: 'Fecha Final', selector: row => {
            const formattedDate = new Date(row.fechaFinal).toLocaleDateString('es-ES', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric'
            });
            return formattedDate;
        }, sortable: true
    }
];

/* Paginations table options */
const paginationComponentOptions = {
    rowsPerPageText: 'Filas por página',
    rangeSeparatorText: 'de',
    selectAllRowsItem: true,
    selectAllRowsItemText: 'Todos',
};

/* Styles Table options */

const customStyles = {
    headCells: {
        style: {
            fontSize: '13px',
            fontWeight: 800,
        },
    },
    headRow: {
        style: {
            backgroundColor: "#eee",
        }
    }
};

export {
    paginationComponentOptions,
    customStyles,
    companiesColumns,
    offersColumns,
    planningColumns
};