import { useState } from "react";
import { useContext, useEffect } from "react";
import { FormGroup, Input, Label, Row, Col } from "reactstrap";
import { useAuthContext } from '../components/AuthProvider'
import { Bar } from 'react-chartjs-2';
import { Chart } from 'react-google-charts';


const Inicio = () => {
    const auth = useAuthContext();
    const [dataForChart, setDataForChart] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [dataReport, setDataReport] = useState({});

    useEffect(() => {
        getInitalReport()
        getOffers();
    }, [])

    const getInitalReport = async () => {

        let response = await fetch("api/reports/GetStartReport", {
            method: 'GET',
            headers: {
                "Authorization": "Bearer " + auth.user
            }
        });
        if (response.ok) {
            debugger;
            let data = await response.json();

            const formattedChartData = [["Element", "Cantidad", { role: "style" }]].concat(
                data.chartData.map(item => [item.element, item.density, item.color])
            );
            setDataReport(prevState => ({
                ...data,
                chartData: formattedChartData
            }));
        }

    }

    const getOffers = async () => {

        let response = await fetch("api/offers/GetOffers", {
            method: 'GET',
            headers: {
                "Authorization": "Bearer " + auth.user
            }
        });

        if (response.ok) {

            let data = await response.json();

            const offers = data.map(item => ({
                ...item,
                detalleOferta: (item.detalleOferta || []).filter(objeto => objeto.estado === true)
            }));

            const estados = Array.from(new Set(offers.map(oferta => oferta.idFEstadoNavigation.nombreEstado)));
            const estadoOfertas = estados.reduce((acc, estado) => {
                acc[estado] = offers.filter(oferta => oferta.idFEstadoNavigation.nombreEstado === estado).length;
                return acc;
            }, {});

            const dataForChart = [['Estado', 'Número de Ofertas']].concat(
                Object.entries(estadoOfertas).map(([estado, numOfertas]) => [estado, numOfertas])
            );

            setDataForChart(dataForChart)
            setIsLoading(false);
        }
    }

    return (
        <>
            <div className="row">

                <div className="col-xl-3 col-md-6 mb-4">
                    <div className="card border-left-primary shadow h-100 py-2">
                        <div className="card-body">
                            <div className="row no-gutters align-items-center">
                                <div className="col mr-2">
                                    <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                                        Cantidad de Clientes Activos</div>
                                    <div className="h5 mb-0 font-weight-bold text-gray-800">{dataReport.totalClients}</div>
                                </div>
                                <div className="col-auto">
                                    <i className="fa fa-building fa-2x text-gray-300"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-xl-3 col-md-6 mb-4">
                    <div className="card border-left-success shadow h-100 py-2">
                        <div className="card-body">
                            <div className="row no-gutters align-items-center">
                                <div className="col mr-2">
                                    <div className="text-xs font-weight-bold text-success text-uppercase mb-1">
                                        Servicios Ofrecidos</div>
                                    <div className="h5 mb-0 font-weight-bold text-gray-800">{dataReport.totalServices}</div>
                                </div>
                                <div className="col-auto">
                                    <i className="fa-solid fa-handshake fa-2x text-gray-300"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-xl-3 col-md-6 mb-4">
                    <div className="card border-left-warning shadow h-100 py-2">
                        <div className="card-body">
                            <div className="row no-gutters align-items-center">
                                <div className="col mr-2">
                                    <div className="text-xs font-weight-bold text-warning text-uppercase mb-1">
                                        Ofertas Finalizadas
                                    </div>
                                    <div className="h5 mb-0 font-weight-bold text-gray-800">{dataReport.totalFinalOffer}</div>
                                </div>
                                <div className="col-auto">
                                    <i className="fas fa-tags fa-2x text-gray-300"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-xl-3 col-md-6 mb-4">
                    <div className="card border-left-info shadow h-100 py-2">
                        <div className="card-body">
                            <div className="row no-gutters align-items-center">
                                <div className="col mr-2">
                                    <div className="text-xs font-weight-bold text-warning text-uppercase mb-1">
                                        Planificaciones Finalizadas
                                    </div>
                                    <div className="h5 mb-0 font-weight-bold text-gray-800">{dataReport.totalFinalPlanning}</div>
                                </div>
                                <div className="col-auto">
                                    <i className="fas fa-book fa-2x text-gray-300"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div className="row">
                <div className="col-xl-6 col-lg-7">
                    <div className="card shadow mb-4">
                        <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between bg-primary">
                            <h6 className="m-0 font-weight-bold text-white">Estado de Ofertas</h6>

                        </div>
                        <div className="card-body">
                            <div style={{ height: 350 }}>
                                <Chart
                                    width={'100%'}
                                    height={'100%'}
                                    chartType="PieChart"
                                    loader={<div>Cargando gráfico</div>}
                                    data={dataForChart}
                                    options={{
                                        title: 'Distribución de Ofertas por Estado',
                                    }}
                                />
                            </div>

                        </div>
                    </div>
                </div>
                <div className="col-xl-6 col-lg-7">
                    <div className="card shadow mb-4">
                        <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between bg-primary">
                            <h6 className="m-0 font-weight-bold text-white">Estado de Planificaciones</h6>

                        </div>
                        <div className="card-body">
                            <div style={{ height: 350 }}>
                                <Chart
                                    width={'100%'}
                                    height={'100%'}
                                    chartType="ColumnChart"
                                    loader={<div>Cargando gráfico</div>}
                                    data={dataReport.chartData}
                                    options={{
                                        title: 'Distribución de Planificaciones por Estado',
                                    }}
                                />
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}

export default Inicio;