import { useRef, useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { Card, CardBody, CardHeader, Button, Modal, ModalHeader, ModalBody, Label, Input, FormGroup, ModalFooter, Row, Col } from "reactstrap"
import { statusModel } from "../emptyModels/emptyModels.js";
import Swal from 'sweetalert2'
import { useAuthContext } from '../components/AuthProvider'

const Status = () => {

    const [status, setStatus] = useState(statusModel);
    const [statuses, setStatuses] = useState([]);
    const [pendiente, setPendiente] = useState(true);
    const [verModal, setVerModal] = useState(false);
    const [search, SetSearch] = useState('');
    const [filter, setFilter] = useState([]);
    const auth = useAuthContext();

    useEffect(() => {
        getStatuses();
    }, [])

    useEffect(() => {
        const result = statuses.filter((item) => {
            return (
                (item.nombreEstado && item.nombreEstado.toLowerCase().includes(search.toLowerCase())) ||
                (item.descripcionEstado && item.descripcionEstado.toLowerCase().includes(search.toLowerCase())) ||
                ((item.estado1 ? "Activo" : "Deshabilitado").toLowerCase().includes(search.toLowerCase()))
            );
        });
        setFilter(result);
    }, [search]);

    /* Columns Data Companies */

    const columns = [
        {
            name: 'Nombre Estado',
            selector: row => row.nombreEstado,
            sortable: true,
        },
        {
            name: 'Descripcion',
            selector: row => row.descripcionEstado,
            sortable: true,
        },
        {
            name: 'Estado',
            selector: row => row.estado1,
            sortable: true,
            cell: row => {
                let clase;
                clase = row.estado1 ? "badge badge-info p-2" : "badge badge-danger p-2"
                return (
                    <span style={{ width: '100px' }} className={clase}>{row.estado1 ? "Activo" : "Deshabilitado"}</span>
                )
            }
        }, {
            name: '',
            cell: row => (
                <>
                    <Button color="primary" size="sm" className="mr-2"
                        onClick={() => openEditModal(row)}
                    >
                        <i className="fas fa-pen-alt"></i>
                    </Button>
                    <Button color="danger" size="sm"
                        onClick={() => deleteStatus(row.idPEstado)}
                    >
                        <i className="fas fa-trash-alt"></i>
                    </Button>
                </>
            ),
        },
    ];
    /* Paginations table options */
    const paginationComponentOptions = {
        rowsPerPageText: 'Filas por pagina',
        rangeSeparatorText: 'de',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Todos',
    };

    /* Styles Table options */

    const customStyles = {
        headCells: {
            style: {
                fontSize: '13px',
                fontWeight: 800,
            },
        },
        headRow: {
            style: {
                backgroundColor: "#eee",
            }
        }
    };

    /* Auxiliary Methods */

    const handleChange = (e) => {
        let value;

        if (e.target.name == "estado1") {
            value = (e.target.value == "true" ? true : false)
        } else {
            value = e.target.value;
        }
        setStatus({
            ...status,
            [e.target.name]: value
        })
    }
    const closeModal = () => {
        setStatus(statusModel)
        setVerModal(!verModal);
    }

    const openEditModal = (data) => {

        setStatus(data);
        setVerModal(!verModal);
    }
    const CustomNoDataComponent = () => (
        <div style={{ padding: '10px', textAlign: 'center' }}>
            No hay registros de estados.
        </div>
    );


    /*CRUD METHODS*/

    /* Get List statuses */
    const getStatuses = async () => {
        let response = await fetch("api/planning/GetStatuses", {
            method: 'GET',
            headers: {
                "Authorization": "Bearer " + auth.user
            }
        });

        if (response.ok) {
            let data = await response.json()
            setStatuses(data)
            setFilter(data);
            setPendiente(false)
        }
    }
    /* Save status */
    const saveStatus = async () => {


        let response;
        let statusModified = { ...status };
        let dateNow = new Date();


        if (status.idPEstado == 0) {

            statusModified.fechaIngreso = dateNow;

            response = await fetch("api/planning/SaveStatus", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8',
                    "Authorization": "Bearer " + auth.user
                },
                body: JSON.stringify(status)
            })

        } else {
            statusModified.fechaModificacion = dateNow;

            response = await fetch("api/planning/EditStatus", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8',
                    "Authorization": "Bearer " + auth.user
                },
                body: JSON.stringify(status)
            })
        }

        if (response.ok) {
            await getStatuses();
            setStatus(statusModel)
            setVerModal(!verModal);

        }
        else if (response.status == 409) {
            Swal.fire(
                'Opp!',
                'No se pudo guardar: ' + await response.text(),
                'warning'
            )

        } else {

            Swal.fire(
                'Opp!',
                'No se pudo guardar.',
                'warning'
            )
        }
    }
    /* Delete Companies */
    const deleteStatus = async (id) => {

        Swal.fire({
            title: 'Esta seguro?',
            text: "Desea eliminar el estado",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si, continuar',
            cancelButtonText: 'No, volver'
        }).then((result) => {
            if (result.isConfirmed) {

                const response = fetch("api/planning/DeleteStatus/" + id, {
                    method: 'POST',
                    headers: {
                        "Authorization": "Bearer " + auth.user
                    }
                })
                    .then(response => {
                        if (response.ok) {

                            getStatuses();

                            Swal.fire(
                                'Eliminado!',
                                'El estado fue eliminado.',
                                'success'
                            )
                        }
                    })
            }
        })
    }

    return (
        <>
            <Card>
                <CardHeader style={{ backgroundColor: '#4e73df', color: "white" }}>
                    Lista de Estados
                </CardHeader>
                <CardBody>
                    <Button color="success" size="sm" onClick={() => setVerModal(!verModal)}>Nuevo Estado</Button>
                    <hr></hr>
                    <DataTable
                        columns={columns}
                        data={filter}
                        progressPending={pendiente}
                        pagination
                        paginationComponentOptions={paginationComponentOptions}
                        customStyles={customStyles}
                        subHeader
                        subHeaderComponent={
                            <input type="text"
                                className="w-25 form-control"
                                placeholder="Buscar..."
                                value={search}
                                onChange={(e) => SetSearch(e.target.value)}

                            />
                        }
                        subHeaderAlign="right"
                        noDataComponent={<CustomNoDataComponent />}
                    />
                </CardBody>
            </Card>
            <Modal isOpen={verModal}>
                <ModalHeader>
                    Detalle Empresa
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <Col sm={6}>
                            <FormGroup>
                                <Label>Nombre Estado</Label>
                                <Input name="nombreEstado" onChange={handleChange} value={status.nombreEstado} />
                            </FormGroup>
                        </Col>
                        <Col sm={6}>
                            <FormGroup>
                                <Label>Descripcion</Label>
                                <Input name="descripcionEstado" onChange={handleChange} value={status.descripcionEstado} />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={6} >
                            <FormGroup>
                                <Label>Estado</Label>
                                <Input className="form-control" type={"select"} name="estado1" onChange={handleChange} value={status.estado1} >
                                    <option value={true}>Activo</option>
                                    <option value={false}>Deshabilitado</option>
                                </Input>
                            </FormGroup>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button size="sm" color="primary" onClick={saveStatus}>Guardar</Button>
                    <Button size="sm" color="danger" onClick={closeModal}>Cerrar</Button>
                </ModalFooter>
            </Modal>
        </>
    );
}

export default Status
    ;
