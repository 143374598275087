import { useRef, useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { Card, CardBody, CardHeader, Button, Modal, ModalHeader, ModalBody, Label, Input, FormGroup, ModalFooter, Row, Col } from "reactstrap"
import { companiesModel } from "../emptyModels/emptyModels.js";
import { companiesRequiredModel } from "../requiredModels/requiredModels.js";
import validateFields from "../requiredModels/useValidationFields.js";
import Swal from 'sweetalert2'
import { useAuthContext } from '../components/AuthProvider'

const Companies = () => {

    const [company, setCompany] = useState(companiesModel);
    const [companies, setCompanies] = useState([]);
    const [pendiente, setPendiente] = useState(true);
    const [verModal, setVerModal] = useState(false);
    const [search, setSearch] = useState('');
    const [filter, setFilter] = useState([]);
    const auth = useAuthContext();

    useEffect(() => {
        getCompanies();
    }, [])

    useEffect(() => {

        const result = companies.filter((item) => {
            return (
                (item.nombreEmpresa && item.nombreEmpresa.toLowerCase().includes(search.toLowerCase())) ||
                (item.ruc && item.ruc.toLowerCase().includes(search.toLowerCase())) ||
                ((item.estado ? "Activo" : "Deshabilitado").toLowerCase().includes(search.toLowerCase())) ||
                (item.fechaIngreso && item.fechaIngreso.toLowerCase().includes(search.toLowerCase())) ||
                (item.descripcionEmpresa && item.descripcionEmpresa.toLowerCase().includes(search.toLowerCase())) || 
                (item.direccion && item.direccion.toLowerCase().includes(search.toLowerCase()))
            );
        });
        setFilter(result);
    }, [search]);

    /* Columns Data Companies */

    const columns = [
        {
            name: 'Nombre',
            selector: row => row.nombreEmpresa,
            sortable: true,
        },
        {
            name: 'Ruc',
            selector: row => row.ruc,
            sortable: true,
        },
        {
            name: 'Información',
            selector: row => row.descripcionEmpresa,
            sortable: true,
        },
        {
            name: 'Dirección',
            selector: row => row.direccion,
            sortable: true,
        },
        {
            name: 'Fecha de Ingreso', selector: row => {
                const formattedDate = new Date(row.fechaIngreso).toLocaleDateString('es-ES', {
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric'
                });
                return formattedDate;
            }, sortable: true
        },
        {
            name: 'Estado',
            selector: row => row.estado,
            sortable: true,
            cell: row => {
                let clase;
                clase = row.estado ? "badge badge-info p-2" : "badge badge-danger p-2"
                return (
                    <span style={{ width: '100px' }} className={clase}>{row.estado ? "Activo" : "Deshabilitado"}</span>
                )
            }
        }, {
            name: '',
            cell: row => (
                <>
                    <Button color="primary" size="sm" className="mr-2"
                        onClick={() => openEditModal(row)}
                    >
                        <i className="fas fa-pen-alt"></i>
                    </Button>
                </>
            ),
        },
    ];
    /* Paginations table options */
    const paginationComponentOptions = {
        rowsPerPageText: 'Filas por página',
        rangeSeparatorText: 'de',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Todos',
    };

    const CustomNoDataComponent = () => (
        <div style={{ padding: '10px', textAlign: 'center' }}>
            No hay registros de clientes.
        </div>
    );

    /* Styles Table options */

    const customStyles = {
        headCells: {
            style: {
                fontSize: '13px',
                fontWeight: 800,
            },
        },
        headRow: {
            style: {
                backgroundColor: "#eee",
            }
        }
    };

    /* Auxiliary Methods */

    const handleChange = (e) => {
        let value;
        const regex = /^[a-zA-Z0-9\s]+$/;

        if (e.target.name == "estado") {
            value = (e.target.value == "true" ? true : false)
        }
        else if (e.target.name == "ruc") {
            value = regex.test(e.target.value) ? e.target.value : e.target.value.slice(0, -1);
        }
        else {
            value = e.target.value;
        }
        setCompany({
            ...company,
            [e.target.name]: value
        })
    }
    const closeModal = () => {
        setCompany(companiesModel)
        setVerModal(!verModal);
    }

    const openEditModal = (data) => {
        setCompany(data);
        console.log(company)
        setVerModal(!verModal);
    }

    /*CRUD METHODS*/

    /* Get List Companies */
    const getCompanies = async () => {
        let response = await fetch("api/companies/GetCompanies", {
            method: 'GET',
            headers: {
                "Authorization": "Bearer " + auth.user
            }
        });
        if (response.ok) {
            let data = await response.json()
            setCompanies(data)
            setFilter(data);
            setPendiente(false)
        }
    }
    /* Save Companies */
    const saveCompany = async () => {

        /*Campos Requeridos*/

        const validationResult = validateFields(companiesRequiredModel, company);

        if (validationResult.requiredFields) {

            Swal.fire(
                'Opp!',
                `Por favor, complete los campos requeridos: ${validationResult.missingFields}.`,
                'error'
            );
            return;
        }

        let response;

        if (company.idPEmpresa == 0) {
            response = await fetch("api/companies/SaveCompany", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8',
                    "Authorization": "Bearer " + auth.user
                },
                body: JSON.stringify(company)
            })

        } else {
            response = await fetch("api/companies/EditCompany", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8',
                    "Authorization": "Bearer " + auth.user
                },
                body: JSON.stringify(company)
            })
        }

        if (response.ok) {
            await getCompanies();
            setCompany(companiesModel)
            setVerModal(!verModal);
            setSearch('')
        }
        else if (response.status == 409) {
            Swal.fire(
                'Opp!',
                'No se pudo guardar: ' + await response.text(),
                'warning'
            )

        }
        else {
            Swal.fire(
                'Opp!',
                'No se pudo guardar: ' + await response.text(),
                'warning'
            )
        }
    }

    /* Delete Companies */
    const deleteCompany = async (id) => {

        Swal.fire({
            title: 'Esta seguro?',
            text: "Desea eliminar la empresa",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si, continuar',
            cancelButtonText: 'No, volver'
        }).then((result) => {
            if (result.isConfirmed) {

            }
        })
    }

    return (
        <>
            <Card>
                <CardHeader style={{ backgroundColor: '#4e73df', color: "white" }}>
                    Lista de Clientes
                </CardHeader>
                <CardBody>
                    <Button color="success" size="sm" onClick={() => setVerModal(!verModal)}>Nuevo Cliente</Button>
                    <hr></hr>
                    <DataTable
                        columns={columns}
                        data={filter}
                        progressPending={pendiente}
                        pagination
                        paginationComponentOptions={paginationComponentOptions}
                        customStyles={customStyles}
                        subHeader
                        subHeaderComponent={
                            <input type="text"
                                className="w-25 form-control"
                                placeholder="Buscar..."
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}

                            />
                        }
                        subHeaderAlign="right"
                        noDataComponent={<CustomNoDataComponent />}
                    />
                </CardBody>
            </Card>
            <Modal isOpen={verModal}>
                <ModalHeader>
                    Detalle Cliente
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <Col sm={6}>
                            <FormGroup>
                                <Label>Nombre Cliente</Label>
                                <span style={{ color: 'red' }}>*</span>
                                <Input name="nombreEmpresa" onChange={handleChange} value={company.nombreEmpresa} />
                            </FormGroup>
                        </Col>
                        <Col sm={6}>
                            <FormGroup>
                                <Label>Ruc</Label>
                                <span style={{ color: 'red' }}>*</span>
                                <Input name="ruc" onChange={handleChange} value={company.ruc} maxLength="14" />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={6}>
                            <FormGroup>
                                <Label>Dirección Cliente</Label>
                                <textarea className="form-control" name="direccion" onChange={handleChange} value={company.direccion} />
                            </FormGroup>
                        </Col>
                        <Col sm={6} >
                            <FormGroup>
                                <Label>Estado</Label>
                                <Input className="form-control" type={"select"} name="estado" onChange={handleChange} value={company.estado} >
                                    <option value={true}>Activo</option>
                                    <option value={false}>Deshabilitado</option>
                                </Input>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <FormGroup>
                                <Label>Información Cliente</Label>
                                <textarea className="form-control"
                                    name="descripcionEmpresa"
                                    onChange={handleChange}
                                    value={company.descripcionEmpresa}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button size="sm" color="primary" onClick={saveCompany}>Guardar</Button>
                    <Button size="sm" color="danger" onClick={closeModal}>Cerrar</Button>
                </ModalFooter>
            </Modal>
        </>
    );
}

export default Companies;