import { useState, useEffect } from "react";
import { Card, CardBody, CardHeader, Col, FormGroup, Spinner, Label, Row, Button } from "reactstrap";
import Datetime from 'react-datetime'
import "react-datetime/css/react-datetime.css";
import Swal from 'sweetalert2'
import { ReportsModel } from "../emptyModels/emptyModels.js";
import moment from 'moment'
import { Select } from 'antd';
import DataTable from 'react-data-table-component';
import { useAuthContext } from '../components/AuthProvider'
import { paginationComponentOptions, customStyles, companiesColumns, offersColumns, planningColumns } from "../customColumnsDatatableEntities/customColumnsDTEntities.js";
import * as XLSX from "xlsx"


const { Option } = Select;

const Reports = () => {
    const [initialDate, setInitialDate] = useState('Seleccione Fecha Inicio');
    const [endDate, setEndDate] = useState('Seleccione Fecha Fin');
    const [typeReport, setTypeReport] = useState(undefined);
    const [typeReportLoaded, setTypeReportLoaded] = useState('');
    const [columnsData, setColumnsData] = useState([]);
    const [dataReport, setDataReport] = useState([]);
    const [pendiente, setPendiente] = useState(false);
    const [isDTVisible, setIsDTVisible] = useState(false);
    const auth = useAuthContext();
    const [loading, setLoading] = useState(false);


    function onChangeSelect(value) {
        setTypeReport(value)
    }
    function onChangeInitalDate(value) {
        setInitialDate(moment(new Date(value._d)).format('DD/MM/YYYY'))
    }
    function onChangeEndDate(value) {
        setEndDate(moment(new Date(value._d)).format('DD/MM/YYYY'))
    }
    const CustomNoDataComponent = () => (
        <div style={{ padding: '10px', textAlign: 'center' }}>
            No hay registros.
        </div>
    );

    const findReport = async () => {

        setColumnsData([])
        setIsDTVisible(true);
        setPendiente(true);

        var initalDates = moment(initialDate, "DD/MM/YYYY");
        var endDates = moment(endDate, "DD/MM/YYYY")

        if (typeReport === undefined) {
            Swal.fire(
                'Opp!',
                `Favor selecciona el tipo de reporte a buscar`,
                'error'
            );
            setPendiente(false);
            return;
        }

        if (!initalDates.isValid() || !endDates.isValid()) {
            Swal.fire(
                'Opp!',
                `Ha ingresado una fecha con un formato incorrecto, favor selecciona una fecha válida`,
                'error'
            );
            setPendiente(false);
            return;
        }

        if (endDates < initalDates) {
            Swal.fire(
                'Opp!',
                `Fecha Fin no puede ser menor a Fecha Inicio`,
                'error'
            );
            setPendiente(false);
            return;
        }

        let response = await fetch(`api/reports/GetCustomReports?typeReport=${typeReport}&initialDate=${initialDate}&endDate=${endDate}`, {
            method: 'GET',
            headers: {
                "Authorization": "Bearer " + auth.user
            }
        });

        if (response.ok) {

            let data = await response.json()

            switch (typeReport) {
                case "Clients":
                case "ActiveClients":
                case "DisabledClientes":
                    setColumnsData(companiesColumns)
                    break;
                case "Offers":
                case "InProcessOffers":
                case "CancelledOffers":
                    setColumnsData(offersColumns)
                    break;
                case "Planning":
                case "InProcessPlanning":
                case "FinishedPlanning":
                case "CancelledPlanning":
                    setColumnsData(planningColumns)
                    break;

            }
            setTypeReportLoaded(typeReport);
            setDataReport(data);
        }
        setPendiente(false);
    }

    const exportExcel = () => {
        setLoading(true);

        if (dataReport.length > 0) {
            let ReportName = '';

            switch (typeReportLoaded) {
                case "Clients":
                    ReportName = 'Clientes.xlsx'
                    break;
                case "ActiveClients":
                    ReportName = 'Clientes Activos.xlsx'
                    break;
                case "DisabledClientes":
                    ReportName = 'Clientes Deshabilitados.xlsx'
                    break;
                case "Offers":
                    ReportName = 'Ofertas.xlsx'
                    break;
                case "InProcessOffers":
                    ReportName = 'Ofertas en Proceso.xlsx'
                    break;
                case "CancelledOffers":
                    ReportName = 'Ofertas Canceladas.xlsx'
                    break;
                case "Planning":
                    ReportName = 'Planificaciones.xlsx'
                    break;
                case "InProcessPlanning":
                    ReportName = 'Planificaciones en Proceso.xlsx'
                    break;
                case "FinishedPlanning":
                    ReportName = 'Planificaciones Finalizadas.xlsx'
                    break;
                case "CancelledPlanning":
                    ReportName = 'Planificaciones Canceladas.xlsx'
                    break;
            }

            const libro = XLSX.utils.book_new();


            const filteredData = dataReport.map(item => {
                const filteredItem = columnsData.reduce((acc, column) => {
                    if (column.id in item) {
                        
                        let value = '';
                        if (column.id === 'estado' && item[column.id] === true) {
                            value = 'ACTIVO'
                        }
                        else if (column.id === 'estado' && item[column.id] === false) {
                            value = 'DESHABILITADO'
                        }
                        else { value = item[column.id] }
                        acc[column.name] = value;
                    }
                    else {
                        let selectorRow = column.id.toString();
                        let properties = selectorRow.split(".");
                        let value = item;

                        for (let i = 0; i < properties.length; i++) {
                            value = value[properties[i]];
                            if (Array.isArray(value)) {
                                value = value.map(item => {
                                    if (Array.isArray(item[properties[i + 1]])) {
                                        return item[properties[i + 1]].join(', ');
                                    }
                                    return item[properties[i + 1]];
                                }).join(', ');
                                break;
                                break;
                            }
                        }
                        acc[column.name] = value;
                    }
                    return acc;
                }, {});
                return filteredItem;
            });


            const hoja = XLSX.utils.json_to_sheet(filteredData);

            XLSX.utils.book_append_sheet(libro, hoja, "Reporte");

            setTimeout(() => {
                XLSX.writeFile(libro, ReportName);
                setLoading(false);
            }, 1000);
        }
    }

    return (
        <>
            <Row>
                <Col sm={12}>
                    <Card>
                        <CardHeader style={{ backgroundColor: '#4e73df', color: "white" }}>
                            Reportes
                        </CardHeader>
                        <CardBody>
                            <Row>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Reporte de: </Label>
                                        <Select
                                            showSearch
                                            size="large"
                                            notFoundContent="Sin elementos"
                                            style={{ width: '100%' }}
                                            optionFilterProp="children"
                                            placeholder="Seleccionar Reporte..."
                                            onChange={onChangeSelect}
                                            filterOption={(input, option) =>
                                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            defaultValue={typeReport}
                                        >
                                            {
                                                ReportsModel.map((item) => {
                                                    return (<Option key={item.reportValue} value={item.reportValue}>{item.reportLabel}</Option>)
                                                })
                                            }
                                        </Select>
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Fecha Inicio:</Label>
                                        <Datetime
                                            placeholder="Seleccionar Fecha"
                                            name="initialDate"
                                            input="true"
                                            onChange={onChangeInitalDate}
                                            dateFormat="DD/MM/YYYY"
                                            value={initialDate}
                                            timeFormat={false}
                                            closeOnSelect={true} />
                                    </FormGroup>
                                </Col>

                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Fecha Fin:</Label>
                                        <Datetime
                                            placeholder="Seleccionar Fecha"
                                            name="endDate"
                                            input="true"
                                            onChange={onChangeEndDate}
                                            dateFormat="DD/MM/YYYY"
                                            value={endDate}
                                            timeFormat={false}
                                            closeOnSelect={true} />
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <Label>Buscar:</Label>
                                    <FormGroup>
                                        <Button color="primary" size="sm" block onClick={findReport}>
                                            <i className="fa fa-search" aria-hidden="true" ></i> Aplicar Filtro
                                        </Button>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <hr></hr>
                            {isDTVisible && (
                                <Row>
                                    <Col sm="12">
                                        <DataTable
                                            columns={columnsData}
                                            data={dataReport}
                                            progressPending={pendiente}
                                            pagination
                                            paginationComponentOptions={paginationComponentOptions}
                                            customStyles={customStyles}
                                            subHeader
                                            subHeaderComponent={!loading ? (
                                                <Button className="custom-export-excel-button" color="success" block onClick={exportExcel}>
                                                    <i className="fa fa-file-excel" aria-hidden="true"></i> Exportar
                                                </Button>
                                            ) : (
                                                <Button style={{ width: '150px', height: '40px' }} color="success" disabled>
                                                    <Spinner type="grow" color="primary" style={{
                                                        height: '1rem',
                                                        width: '1rem'
                                                    }} ></Spinner>
                                                    <span> Generando...</span>
                                                </Button>
                                            )}
                                            subHeaderAlign="right"
                                            noDataComponent={<CustomNoDataComponent />}
                                        />
                                    </Col>
                                </Row>
                            )}
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </>
    )

}

export default Reports;