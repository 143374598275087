import { createContext, useContext, useState } from "react";
import { useAuth }  from './useAuth.js'

const AuthContext = createContext()

const AuthProvider = ({ children }) => {

    const auth = useAuth();

    return <AuthContext.Provider value={auth}>
        {children}
    </AuthContext.Provider>
}

const useAuthContext = () => useContext(AuthContext)

export { useAuthContext, AuthProvider }
