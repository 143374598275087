const validateFields = (requiredModel, model) => {
    const emptyFields = Object.keys(requiredModel).filter(field => !model[field]);
    const validationResult = {
        requiredFields: emptyFields.length > 0,
        missingFields: emptyFields.map(field => requiredModel[field].labelMessage).join(', ')
    };

    return validationResult;
};

export default validateFields;

