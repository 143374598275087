import { useState } from "react";
import { FormGroup, Input, Label, Row, Col } from "reactstrap";
import { useAuth } from "../components/useAuth";
import { useNavigate } from 'react-router-dom';
import '../css/loginForm.css'
import Swal from 'sweetalert2'

function Login() {
    const { signIn } = useAuth();
    const [userName, setUserName] = useState("");
    const [password, setPassword] = useState("");
    const [userNameError, setUserNameError] = useState(false);
    const [passwordError, setPasswordError] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    const navigate = useNavigate();


    const onLogin = async (event) => {
        event.preventDefault();
        
        // Validación de campos vacíos
        if (userName.trim() === '' || password.trim() === '') {
            setUserNameError(userName.trim() === '');
            setPasswordError(password.trim() === '');
            return;
        }

        const data = { userName: userName, password: password };
        await signIn(data).then((response) => {
            if (response) {
                navigate("/");
                window.location.reload();
            } else {

                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Usuario/Contraseña no válidos!',
                })
            }
        });
    }


    const eventSetUserName = (event) => {
        setUserName(event.currentTarget.value);
        setUserNameError(event.currentTarget.value.trim() === '');
    }

    const eventSetPassword = (event) => {
        setPassword(event.currentTarget.value);
        setPasswordError(event.currentTarget.value.trim() === '');
    }

    const togglePasswordVisibility = () => {
        setShowPassword((prevShowPassword) => !prevShowPassword);
    };

    const forgotpassword = (e) => (
        
        Swal.fire({
            text: 'Si olvidaste tu contraseña, por favor contácta con soporte para ayudarte a reestablecerla',
            showClass: {
                popup: 'animate__animated animate__fadeInDown'
            },
            hideClass: {
                popup: 'animate__animated animate__fadeOutUp'
            }
        })
    );
  

    return (
        <div className="container">
            <div className="row">
                <div className="col-lg-3 col-md-2"></div>
                <div className="col-lg-6 col-md-8 login-box">
                    <div className="col-lg-12 login-key">
                        <img style={{ width: '100%' }} src={"./imagen/logoauditorpng.png"} alt="Descripción de la imagen" />
                    </div>
                    <br /> <br /> <br /> <br />
                    <div className="col-lg-12 login-form">
                        <form onSubmit={onLogin}>
                            <div className="form-group input-group" >  
                                <div className="input-group-prepend" style={{ backgroundColor: '#2f3240' }}>
                                    <span className="input-group-text">
                                        <img className="user-icon" src={"./imagen/usericon.png"}/>
                                    </span>
                                </div>
                                <input
                                    placeholder={"Nombre de Usuario"}
                                    type="text"
                                    value={userName}
                                    onInput={eventSetUserName}
                                    style={{ width: '2px' }}
                                    className={`form-control ${userNameError ? 'is-invalid' : ''}`} />
                                {userNameError && (
                                    <div className="invalid-feedback">
                                        Este campo es requerido.
                                    </div>
                                )}
                            </div>
                            <div className="form-group">                             
                                <div className="form-group input-group">
                                    <div className="input-group-prepend" style={{ backgroundColor: '#2f3240' }}>
                                        <span className="input-group-text">
                                            <img className="user-icon" src={"./imagen/passicon.png"} />
                                        </span>
                                    </div>
                                    <input
                                        type={showPassword ? "text" : "password"}
                                        value={password}
                                        onInput={eventSetPassword}
                                        placeholder={"Contraseña"}
                                        className={`form-control ${passwordError ? 'is-invalid' : ''}`} />

                                    <span className="input-group-text" onClick={togglePasswordVisibility}>
                                        <i className={`fa ${showPassword ? 'fa-eye-slash' : 'fa-eye'}`} />
                                    </span>
                                    {passwordError && (
                                        <div className="invalid-feedback">
                                            Este campo es requerido.
                                        </div>
                                    )}
                                </div>

                            </div>
                            <div className="loginbttm">
                                <div className="login-btm login-button">
                                    <button type="submit" id="loginbutton" className="btn btn-outline-primary float-right btn-block">INICIAR SESIÓN</button>
                                </div>
                                <div className="forgot-password-text" style={{ textAlign: 'center', color: '#090909' }}>
                                    <a  onClick={forgotpassword} >¿Olvidó su contraseña?</a>
                                </div>
                            </div>   
                            <div className="additional-content" style={{ visibility: 'hidden', height: '50px' }}></div>                           
                        </form>
                    </div>
                </div>
                <div className="col-lg-3 col-md-2"></div>
            </div>
        </div>
    );
}

export default Login;
