import React, { useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom'
import { useAuthContext } from './AuthProvider'
import jwt_decode from 'jwt-decode';

function RequireAuth({ children }) {
    const location = useLocation();
    const auth = useAuthContext();

    //useEffect(() => {
    //    const token = localStorage.getItem('token');

    //    if (token) {
    //        const decodedToken = jwt_decode(token);
    //        const currentTime = Date.now() / 1000;

    //        if (decodedToken.exp < currentTime) {
    //            // El token ha expirado, redirige al usuario al inicio de sesión
    //            window.location.href = '/login';
    //        }
    //    }
    //}, []);

    if (!auth.user) {
        return <Navigate to='/login' state={{ from: location.pathname }} />
    }
    return children
}

export default RequireAuth