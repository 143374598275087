const companiesRequiredModel = {
    nombreEmpresa: { labelMessage: "Nombre Cliente" },
    ruc: { labelMessage: "Ruc" }  
};

const catServiceRequiredModel = {
    nombreCatServicio: { labelMessage: "Nombre del Servicio" }
};

const empleadoRequiredModel = {
    nombreEmpleado: { labelMessage: "Nombre de Colaborador" },
    apellidoEmpleado: { labelMessage: "Apellido de Colaborador" },
    cedula: { labelMessage: "Cedula de Colaborador" },
    ciudad: { labelMessage: "Ciudad de Colaborador" },
    cargo: { labelMessage: "Cargo de Colaborador" },
    fechaEmpl: { labelMessage: "Fecha de Colaborador" },
    fechaInicial: { labelMessage: "Fecha Inicial de Colaborador" }

};

const planProgressRequiredModel = {
    nombreAvance: { labelMessage: "Nombre de Avance" },
    fechaAvance: { labelMessage: "Fecha de Avance" },
    descripcionAvance: { labelMessage: "Descripción de Avance" },
};

export {
    companiesRequiredModel,
    catServiceRequiredModel,
    empleadoRequiredModel,
    planProgressRequiredModel
};
