import { useLocalStorage } from "./useLocalStorage";

export const useAuth = () => {
    
    const [user, setUser] = useLocalStorage("token");

    const signIn = async (data) => {        
        try {    

            const response = await fetch("api/account", { method: "post", headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data) });
                
            if (response.status == 201) {
                const responseData = await response.text();
                localStorage.setItem("token", responseData);
                setUser(responseData);
                return true;
            }
            else if (response.status == 404) {
                return false;
            }
            else {
                return false;
            }


        } catch (err) {
            console.error(err);
        }

    };

    const signOut = () => {        
        localStorage.removeItem("token")
        setUser(null);
    };

    return { user, signIn, signOut };
};