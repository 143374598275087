import { useRef, useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { Card, CardBody, CardHeader, Button, Modal, ModalHeader, ModalBody, Label, Input, FormGroup, ModalFooter, Row, Col } from "reactstrap"
import { documentsModel } from "../emptyModels/emptyModels.js";
import Swal from 'sweetalert2'
import Datetime from 'react-datetime'
import moment from 'moment'
import React from 'react';
import "react-datetime/css/react-datetime.css";
import { useAuthContext } from '../components/AuthProvider'

const Documents = () => {

    const [document, setDocument] = useState(documentsModel);
    const [documents, setDocuments] = useState([]);
    const [pendiente, setPendiente] = useState(true);
    const [verModal, setVerModal] = useState(false);
    const [search, SetSearch] = useState('');
    const [filter, setFilter] = useState([]);
    const [selectedFile, setSelectedFile] = useState(null);
    const auth = useAuthContext();
    

    useEffect(() => {
        getDocuments();
    }, [])

    useEffect(() => {
        const result = documents.filter((item) => {

            return item.nombreDocumento.toLowerCase().match(search.toLocaleLowerCase()) || item.localidadDocumento.toLowerCase().match(search.toLocaleLowerCase()) ||item.descripcionDocumento.toLowerCase().match(search.toLocaleLowerCase()) || (item.estado ? "Activo" : "Deshabilitado").toLowerCase().match(search.toLocaleLowerCase());
        });
        setFilter(result);
    }, [search]);

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setSelectedFile(file);
        // Puedes realizar cualquier otra l�gica que necesites con el archivo aqu�.
    };

       /* Columns Data Documentos */

    const columns = [
        {
            name: 'Documento',
            selector: row => row.nombreDocumento,
            sortable: true,
        },
        {
            name: 'Descripcion',
            selector: row => row.descripcionDocumento,
            sortable: true,
        },
        {
            name: 'Localidad',
            selector: row => row.localidadDoc,
            sortable: true,
        },
        {
            name: 'Fecha Documento',
            selector: row => row.fechaDocumento,
            sortable: true,
        },
        {
            name: 'Documento',
            selector: row => row.documento1,
            sortable: true,
            cell: row => {
                let clase;
                clase = row.documento1 !== null ? "badge badge-info p-2" : "badge badge-danger p-2"
                return (
                    <span className={clase}>{row.docuemento1 !== null ? "Documento Cargado" : "Sin Documento"}</span>
                )
            }
        },
       
        {
            name: 'Estado',
            selector: row => row.estado,
            sortable: true,
            cell: row => {
                let clase;
                clase = row.estado ? "badge badge-info p-2" : "badge badge-danger p-2"
                return (
                    <span className={clase}>{row.estado ? "Activo" : "Deshabilitado"}</span>
                )
            }
        }, {
            name: '',
            cell: row => (
                <>
                    <Button color="primary" size="sm" className="mr-2"
                        onClick={() => openEditModal(row)}
                    >
                        <i className="fas fa-pen-alt"></i>
                    </Button>
                </>
            ),
        },
    ];
    /* Paginations table options */
    const paginationComponentOptions = {
        rowsPerPageText: 'Filas por pagina',
        rangeSeparatorText: 'de',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Todos',
    };

    /* Styles Table options */

    const customStyles = {
        headCells: {
            style: {
                fontSize: '13px',
                fontWeight: 800,
            },
        },
        headRow: {
            style: {
                backgroundColor: "#eee",
            }
        }
    };

        
    /* Auxiliary Methods */

    const handleChange = (e) => {  
      
        let value;
        let name = e._d ? 'fechaDocumento' : e.target.name;

        if (e._d) {
            value =e._d;
        }
        else if (e.target.name == "estado") {
            value = (e.target.value == "true" ? true : false)
        }else
        {
            value = e.target.value;
        }
        setDocument({
            ...document,
            [name]: value
        })
    }

    const closeModal = () => {
        setDocument(documentsModel)
        setVerModal(!verModal);
    }

    const openEditModal = (data) => {

        setDocument(data);
        console.log(document)
        setVerModal(!verModal);
    }

    /*CRUD METHODS*/

    /* Get List Documents */
    const getDocuments = async () => {
        let response = await fetch("api/documents/GetDocuments", {
            method: 'GET',
            headers: {
                "Authorization": "Bearer " + auth.user
            }
        });      
        if (response.ok) {
            let data = await response.json()
            setDocuments(data)
            setFilter(data);
            setPendiente(false)
        }
    }
    /* Save Document */
    const saveDocument = async () => {
       
        let response;
        if (document.idPDocumento == 0) {
            response = await fetch("api/documents/SaveDocument", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8',
                    "Authorization": "Bearer " + auth.user
                },
                body: JSON.stringify(document)
            })

        } else {
            response = await fetch("api/documents/EditDocument", {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8',
                    "Authorization": "Bearer " + auth.user
                },
                body: JSON.stringify(document)
            })
        }

        if (response.ok) {
            await getDocuments();
            setDocument(documentsModel)
            setVerModal(!verModal);
            SetSearch('')

        } else {

            Swal.fire(
                'Opp!',
                'No se pudo guardar.',
                'warning'
            )
        }
    }   

    /* Delete Document */
    const deleteDocument = async (id) => {
        
        Swal.fire({
            title: 'Esta seguro?',
            text: "Desea eliminar el documento",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si, continuar',
            cancelButtonText: 'No, volver'
        }).then((result) => {
            if (result.isConfirmed) {

            }
        })
    }

    return (
        <>
            <Card>
                <CardHeader style={{ backgroundColor: '#4e73df', color: "white" }}>
                    Lista de Documentos
                </CardHeader>
                <CardBody>
                    <Button color="success" size="sm" onClick={() => setVerModal(!verModal)}>Nuevo Documento</Button>
                    <hr></hr>
                    <DataTable
                        columns={columns}
                        data={filter}
                        progressPending={pendiente}
                        pagination
                        paginationComponentOptions={paginationComponentOptions}
                        customStyles={customStyles}
                        subHeader
                        subHeaderComponent={
                            <input type="text"
                                className="w-25 form-control"
                                placeholder="Buscar..."
                                value={search}
                                onChange={(e) => SetSearch(e.target.value)}

                            />
                        }
                        subHeaderAlign="right"
                    />
                </CardBody>
            </Card>

            <Modal isOpen={verModal}>
                <ModalHeader>
                    Detalle de Documento
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <Col sm={6}>
                            <FormGroup>
                                <Label>Nombre Documento</Label>
                                <Input name="nombreDocumento" onChange={handleChange} value={document.nombreDocumento} />
                            </FormGroup>
                        </Col>
                        <Col sm={6}>
                            <FormGroup>
                                <Label>Localidad</Label>
                                <Input name="localidadDoc" onChange={handleChange} value={document.localidadDoc} />
                            </FormGroup>
                        </Col>

                        <Col sm={6}>
                            <FormGroup>
                                <Label>Fecha del Documento</Label>
                                <Datetime name="fechaDocumento" input="true" onChange={handleChange} dateFormat="DD/MM/YYYY" value={document.fechaDocumento} />
                            </FormGroup>
                        </Col>

                        <Col sm={6} >
                            <FormGroup>
                                <Label>Estado</Label>
                                <Input className="form-control" type={"select"} name="estado" onChange={handleChange} value={document.estado} >
                                    <option value={true}>Activo</option>
                                    <option value={false}>Deshabilitado</option>
                                </Input>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <FormGroup>
                                <Label></Label>
                                <input
                                    type="file"
                                    accept=".pdf" // Limita la selecci�n de archivos a PDF
                                    onChange={handleFileChange}
                                />
                            </FormGroup>
                        </Col>
                        <Col sm={12}>
                            <FormGroup>
                                <Label>Descripcion</Label>
                                <textarea className="form-control" name="descripcionDocumento" onChange={handleChange} value={document.descripcionDocumento} />
                            </FormGroup>
                        </Col>
                        
                    </Row>
    
                </ModalBody>
                <ModalFooter>
                    <Button size="sm" color="primary" onClick={saveDocument}>Guardar</Button>
                    <Button size="sm" color="danger" onClick={closeModal}>Cerrar</Button>
                </ModalFooter>
            </Modal>
        </>
    );
}

export default Documents;

