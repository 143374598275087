import { useRef, useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { Card, CardBody, CardHeader, Button, Modal, ModalHeader, ModalBody, Label, Input, FormGroup, ModalFooter, Row, Col } from "reactstrap"
import { empleadoModel } from "../emptyModels/emptyModels.js";
import Swal from 'sweetalert2'
import Datetime from 'react-datetime'
import "react-datetime/css/react-datetime.css";
import { empleadoRequiredModel } from "../requiredModels/requiredModels.js";
import validateFields from "../requiredModels/useValidationFields.js";
import { useAuthContext } from '../components/AuthProvider'
import { Select } from 'antd';
const { Option } = Select;


const Empleados = () => {

    const [empleado, setEmpleado] = useState(empleadoModel);
    const [empleados, setEmpleados] = useState([]);
    const [pendiente, setPendiente] = useState(true);
    const [verModal, setVerModal] = useState(false);
    const [search, setSearch] = useState('');
    const [filter, setFilter] = useState([]);
    const [editing, setEditing] = useState(false);
    const [users, setUsers] = useState([]);
    const auth = useAuthContext();

    useEffect(() => {
        getEmpleados();
        getUsers();
    }, [])

    useEffect(() => {
        const result = empleados.filter((item) => {
            return (
                (item.nombreEmpleado && item.nombreEmpleado.toLowerCase().includes(search.toLowerCase())) ||
                (item.apellidoEmpleado && item.apellidoEmpleado.toLowerCase().includes(search.toLowerCase())) ||
                (item.cedula && item.cedula.toLowerCase().includes(search.toLowerCase())) ||
                (item.descripcionEmpleado && item.descripcionEmpleado.toLowerCase().includes(search.toLowerCase())) ||
                (item.ciudad && item.ciudad.toLowerCase().includes(search.toLowerCase())) ||
                (item.cargo && item.cargo.toLowerCase().includes(search.toLowerCase())) ||
                (item.fechaEmpl && item.fechaEmpl.toLowerCase().includes(search.toLowerCase())) ||
                (item.fechaInicial && item.fechaInicial.toLowerCase().includes(search.toLowerCase())) ||
                (item.fechaFinal && item.fechaFinal.toLowerCase().includes(search.toLowerCase())) ||
                ((item.estado ? "Activo" : "Deshabilitado").toLowerCase().includes(search.toLowerCase()))
            );
        });
        setFilter(result);
    }, [search]);

    /* Columns Data Empleados */

    const columns = [
        {
            name: 'Nombres',
            selector: row => row.nombreEmpleado,
            sortable: true,
        },
        {
            name: 'Apellidos',
            selector: row => row.apellidoEmpleado,
            sortable: true,
        },
        {
            name: 'Cedula',
            selector: row => row.cedula,
            sortable: true,
        },
        {
            name: 'Descripcion',
            selector: row => row.descripcionEmpleado,
            sortable: true,
        },
        {
            name: 'Ciudad',
            selector: row => row.ciudad,
            sortable: true,
        },
        {
            name: 'Cargo',
            selector: row => row.cargo,
            sortable: true,
        },
        {
            name: 'Fecha Empleado', selector: row => {
                const formattedDate = new Date(row.fechaEmpl).toLocaleDateString('es-ES', {
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric'
                });
                return formattedDate;
            }, sortable: true
        },
        {
            name: 'Fecha de Inicio', selector: row => {
                const formattedDate = new Date(row.fechaInicial).toLocaleDateString('es-ES', {
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric'
                });
                return formattedDate;
            }, sortable: true
        },
        {
            name: 'Fecha de Fin', selector: row => {
                const formattedDate = new Date(row.fechaFinal).toLocaleDateString('es-ES', {
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric'
                });
                return formattedDate;
            }, sortable: true
        },

        {
            name: 'Estado',
            selector: row => row.estado,
            sortable: true,
            cell: row => {
                let clase;
                clase = row.estado ? "badge badge-info p-2" : "badge badge-danger p-2"
                return (
                    <span style={{ width: '100px' }} className={clase}>{row.estado ? "Activo" : "Deshabilitado"}</span>
                )
            }
        }, {
            name: '',
            cell: row => (
                <>
                    <Button color="primary" size="sm" className="mr-2"
                        onClick={() => openEditModal(row)}
                    >
                        <i className="fas fa-pen-alt"></i>
                    </Button>
                </>
            ),
        },
    ];

    /* Paginations table options */
    const paginationComponentOptions = {
        rowsPerPageText: 'Filas por pagina',
        rangeSeparatorText: 'de',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Todos',
    };

    /* Styles Table options */

    const customStyles = {
        headCells: {
            style: {
                fontSize: '13px',
                fontWeight: 800,
            },
        },
        headRow: {
            style: {
                backgroundColor: "#eee",
            }
        }
    };
    const CustomNoDataComponent = () => (
        <div style={{ padding: '10px', textAlign: 'center' }}>
            No hay registros de colaboradores.
        </div>
    );
    const handleChange = (e, name) => {
        let value;
        const regex = /^[a-zA-Z0-9\s]+$/;

        if (name === 'fechaEmpl' || name === 'fechaInicial' || name === 'fechaFinal') {
            
            value = e._d;
        } else if (name === 'estado') {
           
            value = e.target.value === 'true';
        }
        else if (e.target.name == "cedula") {
            value = regex.test(e.target.value) ? e.target.value : e.target.value.slice(0, -1);
        }
        else {
            
            value = e.target.value;
        }

        setEmpleado((prevEmpleado) => ({
            ...prevEmpleado,
            [name]: value
        }));
    };

    function onChangeUser(value) {
        setEmpleado({
            ...empleado,
            ["nombreUsuarioAsignado"]: value
        })
    }
    
    const closeModal = () => {
        setEmpleado(empleadoModel)
        setVerModal(!verModal);
    }

    const openEditModal = (data) => {
        setEmpleado(data);
        console.log(empleado)
        setEditing(true);
        setVerModal(!verModal);        
    }

    const getUsers = async () => {
        let response = await fetch("api/users", { method: "get", headers: { "Authorization": "Bearer " + auth.user } });
        if (response.ok) {
            let data = await response.json()
            setUsers(data);
        }
    }

    /* Get List CatEmpleado */
    const getEmpleados = async () => {
        let response = await fetch("api/empleados/GetEmpleados", {
            method: 'GET',
            headers: {
                "Authorization": "Bearer " + auth.user
            }
        });

        if (response.ok) {
            let data = await response.json()
            setEmpleados(data)
            setFilter(data);
            setPendiente(false)
        }
    }

    /* Save Empleado */
    const saveEmpleado = async () => {

        /*Campos Requeridos*/

        const validationResult = validateFields(empleadoRequiredModel, empleado);

        if (validationResult.requiredFields) {

            Swal.fire(
                'Opp!',
                `Por favor, complete los campos requeridos: ${validationResult.missingFields}.`,
                'error'
            );
            return;
        }

        if (empleado.fechaFinal === '') {
            empleado.fechaFinal = undefined
                       
        }

        let response;
        if (empleado.idPEmpleado == 0) {
            response = await fetch("api/empleados/SaveEmpleado", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8',
                    "Authorization": "Bearer " + auth.user
                },
                body: JSON.stringify(empleado)
            })

        } else {
            response = await fetch("api/empleados/EditEmpleado", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8',
                    "Authorization": "Bearer " + auth.user
                },
                body: JSON.stringify(empleado)
            })
        }

        if (response.ok) {
            await getEmpleados();
            setEmpleado(empleadoModel)
            //setEditing(false);
            setVerModal(!verModal);
            setSearch('')
            
        }
        else if (response.status == 409) {
            Swal.fire(
                'Opp!',
                'No se pudo guardar: ' + await response.text(),
                'warning'
            )

        } else {

            Swal.fire(
                'Opp!',
                'No se pudo guardar.',
                'warning'
            )
        }
    }
    
    return (
        <>
            <Card>
                <CardHeader style={{ backgroundColor: '#4e73df', color: "white" }}>
                    Lista de Colaboradores
                </CardHeader>
                <CardBody>
                    <Button color="success" size="sm" onClick={() => setVerModal(!verModal)}>Nuevo Colaborador</Button>
                    <hr></hr>
                    <DataTable
                        columns={columns}
                        data={filter}
                        progressPending={pendiente}
                        pagination
                        paginationComponentOptions={paginationComponentOptions}
                        customStyles={customStyles}
                        subHeader
                        subHeaderComponent={
                            <input type="text"
                                className="w-25 form-control"
                                placeholder="Buscar..."
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}

                            />
                        }
                        subHeaderAlign="right"
                        noDataComponent={<CustomNoDataComponent />}
                    />
                </CardBody>
            </Card>

            <Modal isOpen={verModal}>
                <ModalHeader>
                    Detalle de Colaborador                    
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <Col sm={6}>
                            <FormGroup>
                                <Label>Nombres</Label>
                                <span style={{ color: 'red' }}>*</span>
                                <Input name="nombreEmpleado" onChange={(e) => handleChange(e, 'nombreEmpleado')} value={empleado.nombreEmpleado} />
                            </FormGroup>
                        </Col>
                        <Col sm={6}>
                            <FormGroup>
                                <Label>Apellidos</Label>
                                <span style={{ color: 'red' }}>*</span>
                                <Input name="apellidoEmpleado" onChange={(e) => handleChange(e, 'apellidoEmpleado')} value={empleado.apellidoEmpleado} />
                            </FormGroup>
                        </Col>
                        <Col sm={6}>
                            <FormGroup>
                                <Label>Cedula</Label>
                                <span style={{ color: 'red' }}>*</span>
                                <Input name="cedula" onChange={(e) => handleChange(e, 'cedula')} value={empleado.cedula} maxLength="14" />
                            </FormGroup>
                        </Col>
                        <Col sm={6}>
                            <FormGroup>
                                <Label>Ciudad</Label>
                                <span style={{ color: 'red' }}>*</span>
                                <Input name="ciudad" onChange={(e) => handleChange(e, 'ciudad')} value={empleado.ciudad} />
                            </FormGroup>
                        </Col>
                        <Col sm={6}>
                            <FormGroup>
                                <Label>Cargo</Label>
                                <span style={{ color: 'red' }}>*</span>
                                <Input name="cargo" onChange={(e) => handleChange(e, 'cargo')} value={empleado.cargo} />
                            </FormGroup>
                        </Col>
                        <Col sm={6}>
                            <FormGroup>
                                <Label>Fecha de Empleado</Label>
                                <span style={{ color: 'red' }}>*</span>
                                <Datetime name="fechaEmpl" input="true" onChange={(e) => handleChange(e, 'fechaEmpl')} dateFormat="DD/MM/YYYY" value={empleado.fechaEmpl} />
                            </FormGroup>
                        </Col>
                        <Col sm={6}>
                            <FormGroup>
                                <Label>Fecha de Ingreso</Label>
                                <span style={{ color: 'red' }}>*</span>
                                <Datetime name="fechaInicial" input="true" onChange={(e) => handleChange(e, 'fechaInicial')} dateFormat="DD/MM/YYYY" value={empleado.fechaInicial} />
                            </FormGroup>
                        </Col>
                        <Col sm={6}>
                            <FormGroup>
                                <Label>Fecha Final</Label>
                                <Datetime name="fechaFinal" input="true" onChange={(e) => handleChange(e, 'fechaFinal')} dateFormat="DD/MM/YYYY" value={empleado.fechaFinal} />
                            </FormGroup>
                        </Col>

                        <Col sm={6} >
                            <FormGroup>
                                <Label>Estado</Label>
                                <Input className="form-control" type={"select"} name="estado" onChange={(e) => handleChange(e, 'estado')} value={empleado.estado} >
                                    <option value={true}>Activo</option>
                                    <option value={false}>Deshabilitado</option>
                                </Input>
                            </FormGroup>
                        </Col>
                        <Col xs={6}>
                            <FormGroup>
                                <Label>Usuario</Label>
                                <Select
                                    showSearch
                                    size="large"
                                    notFoundContent="Sin elementos"
                                    style={{ width: '100%' }}
                                    optionFilterProp="children"
                                    placeholder="Seleccionar Usuario..."
                                    onChange={onChangeUser}
                                    filterOption={(input, option) =>
                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    defaultValue={empleado.nombreUsuarioAsignado === null ? undefined : { key: empleado.nombreUsuarioAsignado }}
                                >
                                    <Option value={0}>Buscar...</Option>
                                    {
                                        users.map((item) => {
                                            return (<Option key={item.userName} value={item.userName}>{item.userName}</Option>)
                                        })
                                    }
                                </Select>
                            </FormGroup>
                        </Col>

                    </Row>
                    <Row>
                        <Col sm={12}>
                            <FormGroup>
                                <Label>Descripcion</Label>
                                <textarea className="form-control" name="descripcionEmpleado" onChange={(e) => handleChange(e, 'descripcionEmpleado')} value={empleado.descripcionEmpleado} />
                            </FormGroup>
                        </Col>
                        
                    </Row>

                </ModalBody>
                <ModalFooter>
                    <Button size="sm" color="primary" onClick={saveEmpleado}>Guardar</Button>
                    <Button size="sm" color="danger" onClick={closeModal}>Cerrar</Button>
                </ModalFooter>
            </Modal>
        </>
    );
}


export default Empleados;