import { useEffect, useState } from 'react';
import { Card, CardBody, CardHeader, Button, Modal, ModalHeader, ModalBody, Label, Input, FormGroup, ModalFooter, Row, Col } from "reactstrap"
import DataTable from 'react-data-table-component';
/*Importar componentes*/
import UploadFiles from '../components/UploadFiles'
import { planningProgressModel } from "../emptyModels/emptyModels.js";
import { planProgressRequiredModel } from "../requiredModels/requiredModels.js";
import { Collapse } from 'antd';
import moment from 'moment'
import Swal from 'sweetalert2'
import Datetime from 'react-datetime'
import "react-datetime/css/react-datetime.css";
import validateFields from "../requiredModels/useValidationFields.js";
import { useAuthContext } from '../components/AuthProvider'

const { Panel } = Collapse;

const PlanningProgress = ({ verProgressModal, setVerProgressModal, planning, planningProgresses, setPlanningProgresses }) => {
    const [planProgress, setPlanProgress] = useState(planningProgressModel);
    const [files, setFiles] = useState([]);
    const [verBodyProgress, setVerBodyProgress] = useState(true);
    const [keyDefaultPanel, setKeyDefaultPanel] = useState('2');
    const [isEdit, setIsEdit] = useState(false);
    const auth = useAuthContext();

    const handleChange = (e) => {

        let value;
        if (e.target.name == "estado") {
            value = (e.target.value == "true" ? true : false)
        } else {
            value = e.target.value;
        }
        setPlanProgress({
            ...planProgress,
            [e.target.name]: value
        })
    }

    function onChangeFechaRegistro(dateString) {
        setPlanProgress({
            ...planProgress,
            ["fechaAvance"]: moment(new Date(dateString._d)).format('DD/MM/YYYY')
        })
    }
    const activeKeyPanelCollapse = (e) => {

        setKeyDefaultPanel(e)
    };

    function onRenderFechaRegistro() {
        setPlanProgress({
            ...planProgress,
            ["fechaAvance"]: moment().format('DD/MM/YYYY')
        })
        return moment().format('DD/MM/YYYY')
    }

    const closeModal = () => {

        setPlanProgress(planningProgressModel)
        setFiles([])
        setKeyDefaultPanel("2")
        setVerProgressModal(!verProgressModal);
    }
    const customPanelStyle = {
        background: '#d5d0d04d',
        borderRadius: 4,
        overflow: 'hidden',
    };
    const closeBtn = (
        <button className="close" onClick={closeModal} type="button">
            &times;
        </button>
    );
    const CustomNoDataComponent = () => (
        <div style={{ padding: '10px', textAlign: 'center' }}>
            No hay historial de avances.
        </div>
    );

    const getPlanProgresses = async (planning) => {

        let response = await fetch("api/planning/getPlanProgresses/" + planning.idPPlanificacion.toString(), {
            method: 'GET',
            headers: {
                "Authorization": "Bearer " + auth.user
            }
        });
        if (response.ok) {
            let data = await response.json()
            setPlanningProgresses(data);
        }
    }
    /* Save Offers */
    
    const cancelOperation = (data) => {
        setPlanProgress(planningProgressModel)
        setFiles([])
        setIsEdit(false)
        setKeyDefaultPanel("2")       
    }
    const savePlanningProgress = async () => {
      
        const validationResult = validateFields(planProgressRequiredModel, planProgress);

        if (validationResult.requiredFields) {

            Swal.fire(
                'Opps!',
                `Por favor, complete los campos requeridos: ${validationResult.missingFields}.`,
                'error'
            );
            return;
        }

        if (files.length === 0) {
            Swal.fire(
                'Opp!',
                `Es requerido subir al menos un archivo para el avance`,
                'error'
            );
            return;
        }

        let response;
        planProgress.idFPlanificacion = planning.idPPlanificacion
        planProgress.files = files

        if (planProgress.idPAvance == 0) {
            response = await fetch("api/planning/SavePlanningProgress", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8',
                    "Authorization": "Bearer " + auth.user
                },
                body: JSON.stringify(planProgress)
            })

        }
        else {
            response = await fetch("api/planning/UpdatePlanningProgress", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8',
                    "Authorization": "Bearer " + auth.user
                },
                body: JSON.stringify(planProgress)
            })
        }
        if (response.ok) {
            await getPlanProgresses(planning);
            setPlanProgress(planningProgressModel)
            setKeyDefaultPanel("2")
            setFiles([])
            setIsEdit(false)
            Swal.fire({
                position: 'center',
                icon: 'success',
                text: 'El registro ha sido almacenado satisfactoriamente',
                showConfirmButton: true
            })
        }
        else if (response.status == 409) {
            Swal.fire(
                'Opp!',
                'No se pudo guardar: ' + await response.text(),
                'warning'
            )

        }
        else {

            Swal.fire(
                'Opp!',
                'No se pudo guardar.',
                'warning'
            )
        }
    }

    const openEditModal = (data) => {
        
        getDocumentsPlanProgress(data.idPAvance)
        setPlanProgress(data)
        setIsEdit(true)
        setKeyDefaultPanel("1")
    }

    const getDocumentsPlanProgress = async (idPAvance) => {
        let response = await fetch("api/planning/getDocumentsPlanProgress/" + idPAvance, {
            method: 'GET',
            headers: {
                "Authorization": "Bearer " + auth.user
            }
        });

        if (response.ok) {
            
            let data = await response.json()
            setFiles(data)
        }
    }
    /* Styles Table options */

    const customStyles = {
        headCells: {
            style: {
                fontSize: '13px',
                fontWeight: 800,
            },
        },
        headRow: {
            style: {
                backgroundColor: "#eee",
            }
        }
    };

    /* Paginations table options */
    const paginationComponentOptions = {
        rowsPerPageText: 'Filas por pagina',
        rangeSeparatorText: 'de',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Todos',
    };
    const columns = [
        {
            name: 'Avance',
            selector: row => row.nombreAvance,
            sortable: true,
        },
        {
            name: 'Descripción Avance',
            selector: row => row.descripcionAvance,
            sortable: true,
        },
        {
            name: 'Fecha Avance', selector: row => {
                const formattedDate = new Date(row.fechaAvance).toLocaleDateString('es-ES', {
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric'
                });
                return formattedDate;
            }, sortable: true
        },
        {
            name: 'Estado',
            selector: row => row.estado,
            sortable: true,
            cell: row => {
                let clase;
                clase = row.estado ? "badge badge-info p-2" : "badge badge-danger p-2"
                return (
                    <span className={clase}>{row.estado ? "Activo" : "Deshabilitado"}</span>
                )
            }
        }, {
            name: '',
            cell: row => (
                <>
                    <Button color="primary" size="sm" className="mr-2"
                        onClick={() => openEditModal(row)} title="Editar/Ver Detalle"
                    >
                        <i className="fas fa-pen-alt"></i>
                    </Button>
                </>
            ),
        },
    ];

    return (
        <>
            <Modal isOpen={verProgressModal} size="xl" centered={true} >
                <ModalHeader toggle={verProgressModal} close={closeBtn}>
                    Avances de la Planificación
                </ModalHeader>
                <ModalBody>
                    <Collapse accordion={true} activeKey={[keyDefaultPanel]} onChange={activeKeyPanelCollapse}>
                        <Panel header={isEdit ? "Editando Avance" : "Registrar Avance"} key="1" style={customPanelStyle}>
                            <Row>
                                <Col sm={6}>
                                    <FormGroup>
                                        <Label>Nombre Avance</Label>
                                        <Input name="nombreAvance" onChange={handleChange} value={planProgress.nombreAvance} />
                                    </FormGroup>
                                </Col>
                                <Col sm={6}>
                                    <FormGroup>
                                        <Label>Planificación</Label>
                                        <Input name="nombrePlanificacion" disabled={true} value={planning.nombrePlanificacion} />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={6}>
                                    <FormGroup>
                                        <Label>Fecha Avance</Label>
                                        <Datetime
                                            placeholder="Seleccionar Fecha"
                                            name="fechaAvance"
                                            input="true"
                                            onChange={onChangeFechaRegistro}
                                            dateFormat="DD/MM/YYYY"
                                            value={planProgress.fechaAvance === "" ? onRenderFechaRegistro() : moment(planProgress.fechaAvance).format('DD/MM/YYYY')}
                                            timeFormat={false}
                                            closeOnSelect={true} />
                                    </FormGroup>
                                </Col>
                                <Col sm={6}>
                                    <FormGroup>
                                        <Label>Estado</Label>
                                        <Input className="form-control" type={"select"} name="estado" onChange={handleChange} value={planProgress.estado}>
                                            <option value={true}>Activo</option>
                                            <option value={false}>Deshabilitado</option>
                                        </Input>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={12}>
                                    <FormGroup>
                                        <Label>Descripción Avance</Label>
                                        <textarea className="form-control"
                                            name="descripcionAvance"
                                            onChange={handleChange}
                                            value={planProgress.descripcionAvance}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={12}>
                                    <FormGroup>
                                        <Label>Cargar Archivo</Label>
                                        <UploadFiles files={files} setFiles={setFiles} />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={6}>
                                    <span style={{ paddingRight: '10px' }}>
                                        <Button size="sm" color="success" onClick={savePlanningProgress}>
                                            {isEdit ? "Actualizar Avance" : "Guardar Avance"}
                                        </Button>
                                    </span>

                                    <Button size="sm" color="danger" onClick={cancelOperation}>
                                        Cancelar
                                    </Button>
                                </Col>
                            </Row>

                        </Panel>

                        <Panel header="Historial Avances" key="2" style={customPanelStyle}>
                            <DataTable
                                columns={columns}
                                data={planningProgresses}
                                pagination
                                paginationComponentOptions={paginationComponentOptions}
                                customStyles={customStyles}
                                noDataComponent={<CustomNoDataComponent />}
                            />
                        </Panel>
                    </Collapse>
                </ModalBody>

                <ModalFooter>
                    <Button size="sm" color="danger" onClick={closeModal}>Cerrar</Button>
                </ModalFooter>
            </Modal>
        </>
    );

}

export default PlanningProgress;