import React, { useEffect, useState } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Start from "./views/Start";
import NotFound from "./views/NotFound";
import Layout from './components/Layout';
import Login from './views/Login';
import RequireAuth from './components/RequireAuth';
import TokenValidator from "./components/TokenValidator";
import { useAuthContext } from './components/AuthProvider'
import jwt_decode from 'jwt-decode';
import Profile from './views/Profile';

const App = () => {    
    
    const auth = useAuthContext();
    const[routes, setRoutes] = useState(null);

    useEffect(() => {

        function initRoutes() {
            
            if (auth.user) {
                const decodedToken = jwt_decode(auth.user);
                const userviews = JSON.parse(decodedToken.userAccess);

                setRoutes(
                    userviews.map((item, key) => {
                        return getRoutes(item, key);
                    })
                )
            } else {
                setRoutes(
                    <> </>          
                )
            }
        }

        function getRoutes(item, key) {
            
            if (item.UserAccessDetails.CanView) {
                try {
                    const Component = require(`./views/${item.Componente}`).default;
                    return (
                        <Route key={key} path={item.NavLink} element={<> <TokenValidator /> <RequireAuth> <Component /> </RequireAuth> </>} />
                    );
                }catch(e) { return }
            }
            return null
        }
        initRoutes();
    }, [])  
    

    return (        
        <>            
            <BrowserRouter>
                <Routes>                 
                    <Route index path='/Login' element={<Login />} />
                    
                    <Route path='/' element={ auth.user ? <Layout /> : <Login /> }>
                        <Route index element={<Start />} />
                        <Route path="/Profile" element={<> <TokenValidator /> <RequireAuth> <Profile /> </RequireAuth> </> } />

                        {/*Rutas din�micas*/}
                        {routes}
                        {/*Rutas din�micas*/}
                    </Route>
                    <Route path='*' element={<NotFound />} />
                </Routes>
            </BrowserRouter>            
        </>
    )
}

export default App;