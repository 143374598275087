import { useEffect, useState } from 'react';
import { rolesModel } from "../emptyModels/emptyModels";
import DataTable from 'react-data-table-component';
import { Card, CardBody, CardHeader, Button, Modal, ModalHeader, ModalBody, Label, Input, FormGroup, ModalFooter, Row, Col } from "reactstrap"
import { useAuthContext } from '../components/AuthProvider'
import Swal from 'sweetalert2'

const Roles = () => {
    const [role, setRole] = useState(rolesModel);
    const [roles, setRoles] = useState([]);
    const [pending, setPending] = useState(true);
    const [viewModal, setViewModal] = useState(false);
    const [search, setSearch] = useState('');
    const [filter, setFilter] = useState([]);

    const [access, setAccess] = useState(role.access);
    const auth = useAuthContext();

    useEffect(() => {
        getRoles();
        getAccess();
    }, []);

    useEffect(() => {
        const result = roles.filter((item) => {
            return (
                (item.name && item.name.toLowerCase().includes(search.toLowerCase())) ||
                (item.createdBy && item.createdBy.toLowerCase().includes(search.toLowerCase())) ||
                (item.createdOn && item.createdOn.toLowerCase().includes(search.toLowerCase()))
            );           
        });
        setFilter(result);
    }, [search]);

    const toggleCheckbox = (row, propertyName) => {
        // Create a copy of the access array to avoid mutating the state directly
        const updatedAccess = [...access];

        // Find the corresponding row in the updatedAccess array
        const updatedRow = updatedAccess.find(item => item.id === row.id);

        // Toggle the specified property for the clicked row
        if (updatedRow) {
            updatedRow.userAccessDetails[propertyName] = !updatedRow.userAccessDetails[propertyName];

            // Update the state with the modified access array
            setAccess(updatedAccess);
        }
    };

    const columns = [
        {
            name: 'Nombre',
            selector: row => row.name,
            sortable: true,
        },
        {
            name: 'Creado por',
            selector: row => row.createdBy,
            sortable: true,
        },
        {
            name: 'Fecha Creacion', selector: row => {
                const formattedDate = new Date(row.createdOn).toLocaleDateString('es-ES', {
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric'
                });
                return formattedDate;
            }, sortable: true
        },
        {
            name: '',
            cell: row => (
                <>
                    <Button color="primary" size="sm" className="mr-2"
                        onClick={() => openEditModal(row)}
                    >
                        <i className="fas fa-pen-alt"></i>
                    </Button>

                    {
                        row.isEnabled ?
                            <Button color="success" size="sm" title="Presiona este boton para deshabilitar el rol" className="mr-2"
                                onClick={() => disableRole(row)}
                            >
                                <i className="fas fa-unlock"></i>
                            </Button>
                            :
                            <Button color="danger" size="sm" title="Presiona este boton para habilitar el rol" className="mr-2"
                                onClick={() => enableRole(row)}
                            >
                                <i className="fas fa-lock"></i>
                            </Button>


                    }
                </>
            ),
        },
    ];

    const accessColumns = [
        {
            name: 'Menu',
            selector: row => row.labelItem,
            sortable: true,
        },
        {
            name: 'Ver',
            selector: row => (<input name='canView' checked={row.userAccessDetails.canView} type="checkbox" onChange={() => toggleCheckbox(row, 'canView')} />),
            sortable: false,
        },
        {
            name: 'Guardar',
            selector: row => (<input name='canWrite' checked={row.userAccessDetails.canWrite} type="checkbox" onChange={() => toggleCheckbox(row, 'canWrite')} />),
            sortable: false,
        },
        {
            name: 'Modificar',
            selector: row => (<input name='canModify' checked={row.userAccessDetails.canModify} type="checkbox" onChange={() => toggleCheckbox(row, 'canModify')} />),
            sortable: false,
        },
    ];

    const customStyles = {
        headCells: {
            style: {
                fontSize: '13px',
                fontWeight: 800,
            },
        },
        headRow: {
            style: {
                backgroundColor: "#eee",
            }
        }
    };

    const handleChange = (e) => {
        let value;

        if (e.target.name == "Estado") {
            value = (e.target.value == "true" ? true : false)
        } else {
            value = e.target.value;
        }
        setRole({
            ...role,
            [e.target.name]: value
        })
    }

    const closeModal = () => {
        setRole(rolesModel);
        setViewModal(!viewModal);
    }

    const openEditModal = (data) => {
        setRole(data);
        getAccessBySelectedRol(data.id);
        setViewModal(!viewModal);
    }

    const getRoles = async () => {
        let response = await fetch("api/roles", { method: "get", headers: { "Authorization": "Bearer " + auth.user } });
        if (response.ok) {
            let data = await response.json()
            setFilter(data);
            setRoles(data)
            setPending(false)
        }
    }

    const getAccess = async () => {
        let response = await fetch("api/access", { method: "get", headers: { "Authorization": "Bearer " + auth.user } });
        if (response.ok) {
            let data = await response.json()
            setAccess(data)
        }
    }

    const getAccessBySelectedRol = async (role) => {
        let response = await fetch("api/access/byrole?RoleId=" + role, { method: "get", headers: { "Authorization": "Bearer " + auth.user } } );
        if (response.ok) {
            let data = await response.json();
            setAccess(data);
        }
    }

    const saveRole = async () => {
        let response;
        
        role.access = access;

        response = await fetch("api/roles", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json;charset=utf-8',
                'Authorization': "Bearer " + auth.user
            },
            body: JSON.stringify(role)
        });

        if (response.ok) {
            await getRoles();
            setViewModal(!viewModal);

        }        
        else {

            let errorMessage = await response.text();

            Swal.fire(
                'Opp!',
                'No se pudo guardar. ' + errorMessage,
                'warning'
            )
        }
    };

    const disableRole = async (role) => {
        let response;
        const willDo = await
            Swal.fire({
                title: "¿Estás seguro?",
                text: "El rol se va a deshabilitar",
                icon: "warning",
                dangerMode: true,
            });

        if (willDo.value) {
            response = await fetch("api/roles/disable?Id=" + role.id, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8',
                    'Authorization': "Bearer " + auth.user
                }
            });

            if (response.ok) {
                await getRoles();
            } else {

                let errorMessage = await response.text();

                Swal.fire(
                    'Opp!',
                    'No se pudo guardar. ' + errorMessage,
                    'warning'
                )
            }
        }
    };

    const enableRole = async (role) => {
        let response;
        const willDo = await
            Swal.fire({
                title: "¿Estás seguro?",
                text: "El rol se va a habilitar",
                icon: "warning",
                dangerMode: true,
            });

        if (willDo.value) {

            response = await fetch("api/roles/enable?Id=" + role.id, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8',
                    'Authorization': "Bearer " + auth.user
                }
            });

            if (response.ok) {
                await getRoles();
            } else {

                let errorMessage = await response.text();

                Swal.fire(
                    'Opp!',
                    'No se pudo guardar. ' + errorMessage,
                    'warning'
                )
            }
        }
    };

    return (
        <>
            <Card>
                <CardHeader style={{ backgroundColor: '#4e73df', color: "white" }}>
                    Lista de Roles
                </CardHeader>
                <CardBody>
                    <Button color="success" size="sm" onClick={() => { setViewModal(!viewModal); getAccess(); }}>Nuevo Rol</Button>
                    <hr></hr>
                    <DataTable
                        columns={columns}
                        data={filter}
                        progressPending={pending}
                        pagination
                        customStyles={customStyles}
                        subHeader
                        subHeaderComponent={
                            <input type="text"
                                className="w-25 form-control"
                                placeholder="Buscar..."
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}
                            />
                        }
                        subHeaderAlign="right"
                    />
                </CardBody>
            </Card>
            <Modal isOpen={viewModal}>
                <ModalHeader>
                    Detalle Rol
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <Col sm={12}>
                            <FormGroup>
                                <Label>Nombre del Rol</Label>
                                <Input name="name" onChange={handleChange} value={role.name} />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <DataTable
                            columns={accessColumns}
                            data={access}
                            progressPending={pending}
                            customStyles={customStyles}
                        />
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button size="sm" color="primary" onClick={saveRole}>Guardar</Button>
                    <Button size="sm" color="danger" onClick={closeModal}>Cerrar</Button>
                </ModalFooter>
            </Modal>
        </>
    );
};

export default Roles;