import { useEffect, useState } from 'react';
import { usersModel, userChangePassowrd } from "../emptyModels/emptyModels";
import DataTable from 'react-data-table-component';
import Select from 'react-select';
import { Card, CardBody, CardHeader, Button, Modal, ModalHeader, ModalBody, Label, Input, FormGroup, ModalFooter, Row, Col } from "reactstrap"
import { useAuthContext } from '../components/AuthProvider'
import Swal from 'sweetalert2'

const Users = () => {
    const [user, setUser] = useState(usersModel);
    const [userPassword, setUserPassword] = useState(userChangePassowrd);
    const [roles, setRoles] = useState([]);
    const [users, setUsers] = useState([]);
    const [pending, setPending] = useState(true);
    const [viewModal, setViewModal] = useState(false);
    const [viewEditModal, setViewEditModal] = useState(false);
    const [viewChangePasswordModal, setViewChangePasswordModal] = useState(false);
    const auth = useAuthContext();
    const [selectedRol, setSelectedRol] = useState();
    const [search, setSearch] = useState('');
    const [filter, setFilter] = useState([]);

    useEffect(() => {
        getUsers();
        getRoles();
    }, []);


    useEffect(() => {
        const result = users.filter((item) => {
            return (
                (item.userName && item.userName.toLowerCase().includes(search.toLowerCase())) ||
                (item.email && item.email.toLowerCase().includes(search.toLowerCase())) ||
                (item.roleName && item.roleName.toLowerCase().includes(search.toLowerCase())) 
            );
        });
        setFilter(result);
    }, [search]);

    const columns = [
        {
            name: 'Nombre Usuario',
            selector: row => row.userName,
            sortable: true,
        },
        {
            name: 'Email',
            selector: row => row.email,
            sortable: true,
        },
        {
            name: 'Rol',
            selector: row => row.roleName,
            sortable: true,
        },
        {
            name: '',
            cell: row => (
                <>
                    {
                        row.lockoutEnabled ?
                            <Button color="danger" size="sm" title="Presiona este boton para habilitar al usuario" className="mr-2"
                                onClick={() => enableUser(row)}
                            >
                                <i className="fas fa-lock"></i>
                            </Button>
                            :
                            <Button color="success" size="sm" title="Presiona este boton para deshabilitar al usuario" className="mr-2"
                                onClick={() => disableUser(row)}
                            >
                                <i className="fas fa-unlock"></i>
                            </Button>


                    }

                    <Button color="primary" size="sm" className="mr-2"
                        onClick={() => openEditModal(row)}
                    >
                        <i className="fas fa-pen-alt"></i>
                    </Button>

                    <Button color="success" size="sm" title="Cambiar password" className="mr-2"
                        onClick={() => openChangePasswordModal(row)}
                    >
                        <i className="fas fa-key"></i>
                    </Button>
                </>
            ),
        },
    ];

    const customStyles = {
        headCells: {
            style: {
                fontSize: '13px',
                fontWeight: 800,
            },
        },
        headRow: {
            style: {
                backgroundColor: "#eee",
            }
        }
    };

    const handleChange = (e) => {
        let value;

        if (e.target.name == "Estado") {
            value = (e.target.value == "true" ? true : false)
        } else {
            value = e.target.value;
        }
        setUser({
            ...user,
            [e.target.name]: value
        })
    };

    const handleUserPasswordChange = (e) => {
        let value;

        if (e.target.name == "Estado") {
            value = (e.target.value == "true" ? true : false)
        } else {
            value = e.target.value;
        }
        setUserPassword({
            ...userPassword,
            [e.target.name]: value
        })
    };

    const handleSelectChange = (selectedValue) => {
        setSelectedRol(selectedValue);
    };

    const closeModal = () => {
        setUser(usersModel);
        setViewModal(!viewModal);
    }

    const closeEditModal = () => {
        setUser(usersModel);
        setViewEditModal(!viewEditModal);
    }

    const closeChangePasswordView = () => {
        setUser(usersModel);
        setViewChangePasswordModal(!viewChangePasswordModal);
    }

    const openNewModal = (data) => {
        setUser(data);
        setViewModal(!viewModal);
    }

    const openEditModal = (data) => {
        setUser(data);
        var selectedObject = { label: data.roleName, value: data.idRol };
        setSelectedRol(selectedObject);
        setViewEditModal(!viewModal);
    }

    const openChangePasswordModal = (data) => {
        setUser(data);
        setUserPassword(data);
        setViewChangePasswordModal(!viewChangePasswordModal);
    }

    const CustomNoDataComponent = () => (
        <div style={{ padding: '10px', textAlign: 'center' }}>
            No hay registros de usuarios.
        </div>
    );

    const getUsers = async () => {
        let response = await fetch("api/users", { method: "get", headers: { "Authorization": "Bearer " + auth.user } });
        if (response.ok) {
            let data = await response.json()
            setUsers(data);
            setFilter(data);
            setPending(false);
        }
    }

    const getRoles = async () => {
        let response = await fetch("api/roles", { method: "get", headers: { "Authorization": "Bearer " + auth.user } });
        if (response.ok) {            
            let data = await response.json();
            let filteredData = data.map((rol) => ({
                value: rol.id,
                label: rol.name
            }));
            setRoles(filteredData);
            setPending(false);
        }
    }

    const saveUser = async () => {
        
        let response;
        user.IdRol = selectedRol.value;

        if (usersModel.Id == "") {
            response = await fetch("api/users", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8',
                    'Authorization': "Bearer " + auth.user
                },
                body: JSON.stringify(user)
            })

        } else {
            response = await fetch("api/users", {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8',
                    'Authorization': "Bearer " + auth.user
                },
                body: JSON.stringify(user)
            })
        }

        if (response.ok) {
            await getUsers();
            setViewModal(!viewModal);

        } else {

            let errorMessage = await response.text();

            Swal.fire(
                'Opp!',
                'No se pudo guardar. ' + errorMessage,
                'warning'
            )
        }
    };

    const changePassword = async () => {
        let response;

        response = await fetch("api/users/changepassword", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json;charset=utf-8',
                'Authorization': "Bearer " + auth.user
            },
            body: JSON.stringify(userPassword)
        });

        if (response.ok) {
            await getUsers();
            setViewChangePasswordModal(!viewChangePasswordModal);

        } else {

            let errorMessage = await response.text();

            Swal.fire(
                'Opp!',
                'No se pudo guardar. ' + errorMessage,
                'warning'
            )
        }
    };

    const editUser = async () => {
        let response;
        user.IdRol = selectedRol.value;

        response = await fetch("api/users/edit", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json;charset=utf-8',
                'Authorization': "Bearer " + auth.user
            },
            body: JSON.stringify(user)
        })

        if (response.ok) {
            await getUsers();
            setViewEditModal(!viewEditModal);

        } else {

            let errorMessage = await response.text();

            Swal.fire(
                'Opp!',
                'No se pudo guardar. ' + errorMessage,
                'warning'
            )
        }
    };

    const disableUser = async (user) => {
        let response;
        const willDo = await
            Swal.fire({
                title: 'Esta seguro?',
                text: "Desea deshabilitar al usuario",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Si, continuar',
                cancelButtonText: 'No, volver'
            });

        if (willDo.value) {
            response = await fetch("api/users/disable?Id=" + user.id, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8',
                    'Authorization': "Bearer " + auth.user
                }
            });

            if (response.ok) {
                await getUsers();
                setSearch('');
            } else {

                let errorMessage = await response.text();

                Swal.fire(
                    'Opp!',
                    'No se pudo guardar. ' + errorMessage,
                    'warning'
                )
            }
        }
    };

    const enableUser = async (user) =>
    {
        let response;
        const willDo = await
            Swal.fire({
                title: 'Esta seguro?',
                text: "Desea habilitar al usuario",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Si, continuar',
                cancelButtonText: 'No, volver'
            });

        if (willDo.value) {

                    response = await fetch("api/users/enable?Id=" + user.id, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json;charset=utf-8',
                            'Authorization': "Bearer " + auth.user
                        }
                    });
           
            if (response.ok) {               
                await getUsers();
                setSearch('');
                    } else {

                        let errorMessage = await response.text();

                        Swal.fire(
                            'Opp!',
                            'No se pudo guardar. ' + errorMessage,
                            'warning'
                        )
            }
        }
    };
    return (
        <>
            <Card>
                <CardHeader style={{ backgroundColor: '#4e73df', color: "white" }}>
                    Lista de Usuarios
                </CardHeader>
                <CardBody>
                    <Button color="success" size="sm" onClick={() => {
                        setViewModal(!viewModal);
                        setUser(usersModel);
                        setSelectedRol({ label:"Select", value: "" });
                    }}>Nuevo Usuario</Button>
                    <hr></hr>
                    <DataTable
                        columns={columns}
                        data={filter}
                        progressPending={pending}
                        pagination
                        customStyles={customStyles}
                        subHeader
                        subHeaderComponent={
                            <input type="text"
                                className="w-25 form-control"
                                placeholder="Buscar..."
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}
                            />
                        }
                        subHeaderAlign="right"
                        noDataComponent={<CustomNoDataComponent />}
                    />
                </CardBody>
            </Card>
            <Modal isOpen={viewModal}>
                <ModalHeader>
                    Detalle Usuario
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <Col sm={12}>
                            <FormGroup>
                                <Label>Nombre de Usuario:</Label>
                                <Input name="UserName" onChange={handleChange} value={user.userName} />
                            </FormGroup>
                        </Col>                        
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <FormGroup>
                                <Label>Email</Label>
                                <Input name="Email" onChange={handleChange} value={user.email} />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <FormGroup>
                                <Label>Rol</Label>
                                <Select options={roles} onChange={handleSelectChange} value={selectedRol}></Select>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <FormGroup>
                                <Label>Password</Label>
                                <Input name="Password" type="password" onChange={handleChange} value={user.password} />
                            </FormGroup>
                        </Col>                        
                    </Row>        
                    
                </ModalBody>
                <ModalFooter>
                    <Button size="sm" color="primary" onClick={saveUser}>Guardar</Button>
                    <Button size="sm" color="danger" onClick={closeModal}>Cerrar</Button>
                </ModalFooter>
            </Modal>
            <Modal isOpen={viewEditModal}>
                <ModalHeader>
                    Detalle Usuario
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <Col sm={12}>
                            <FormGroup>
                                <Label>Nombre de Usuario:</Label>
                                <Input name="UserName" onChange={handleChange} value={user.userName} disabled/>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <FormGroup>
                                <Label>Email</Label>
                                <Input name="Email" onChange={handleChange} value={user.email} disabled/>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <FormGroup>
                                <Label>Rol</Label>
                                <Select options={roles} onChange={handleSelectChange} value={selectedRol}></Select>
                            </FormGroup>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button size="sm" color="primary" onClick={editUser}>Guardar</Button>
                    <Button size="sm" color="danger" onClick={closeEditModal}>Cerrar</Button>
                </ModalFooter>
            </Modal>
            <Modal isOpen={viewChangePasswordModal}>
                <ModalHeader>
                Cambio de Password
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <Col sm={12}>
                            <FormGroup>
                                <Label>Nombre de Usuario:</Label>
                                <Input name="UserName" onChange={handleUserPasswordChange} value={userPassword.userName} disabled />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <FormGroup>
                                <Label>Nuevo Password</Label>
                                <Input name="NewPassword" type="password" onChange={handleUserPasswordChange} value={userPassword.newPassword} />
                            </FormGroup>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button size="sm" color="primary" onClick={changePassword}>Guardar</Button>
                    <Button size="sm" color="danger" onClick={closeChangePasswordView}>Cerrar</Button>
                </ModalFooter>
            </Modal>
        </>
    );
};

export default Users;