import { useRef, useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { Card, CardBody, CardHeader, Button, Modal, ModalHeader, ModalBody, Label, Input, FormGroup, ModalFooter, Row, Col } from "reactstrap"

/*Importar componentes*/
import UploadFiles from '../components/UploadFiles'
import PieOffers from './PieOffers'
import { offerModel } from "../emptyModels/emptyModels.js";
import { offerEmployees } from "../emptyModels/emptyModels.js";
import { Select, Spin } from 'antd';
import moment from 'moment'
import Swal from 'sweetalert2'
import Datetime from 'react-datetime'
import "react-datetime/css/react-datetime.css";
import { useAuthContext } from '../components/AuthProvider'
const { Option } = Select;


const Offers = () => {  
    const [offer, setOffer] = useState(offerModel);
    const [companies, setCompanies] = useState([]);
    const [catservices, setCatServices] = useState([]);
    const [statuses, setStatuses] = useState([]);
    const [employess, setEmployess] = useState([]);
    const [verModal, setVerModal] = useState(false);
    const [files, setFiles] = useState([]);
    const [offerEmployee, setOfferEmployees] = useState(offerEmployees);
    const [offers, setOffers] = useState([]);
    const [hidden, setHidden] = useState(false);
    const [search, setSearch] = useState('');
    const [filter, setFilter] = useState([]);
    const auth = useAuthContext();

    useEffect(() => {
        getOffers();
        initializeData();
    }, [])

    const offerModelEmpty = {
        IdPOferta: 0,
        UsuarioIngreso: "",
        nombreOferta: "",
        DescripcionOferta: "",
        IdFCatServicio: 0,
        IdFEmpresa: 0,
        IdPEmpleado: 0,
        IdFEstado: 0,
        FechaOferta: ""
    };

    const initializeData = async () => {
        try {
            const [companiesResponse, catServicesResponse, statusesResponse, employeesResponse] = await Promise.all([
                fetch("api/companies/GetCompanies", {
                    method: 'GET',
                    headers: {
                        "Authorization": "Bearer " + auth.user
                    }
                }),
                fetch("api/catservices/GetCatServices", {
                    method: 'GET',
                    headers: {
                        "Authorization": "Bearer " + auth.user
                    }
                }),
                fetch("api/planning/GetStatuses", {
                    method: 'GET',
                    headers: {
                        "Authorization": "Bearer " + auth.user
                    }
                }),
                fetch("api/empleados/GetEmpleados", {
                    method: 'GET',
                    headers: {
                        "Authorization": "Bearer " + auth.user
                    }
                })
            ]);

            const [companiesData, catServicesData, statusesData, employeesData] = await Promise.all([
                companiesResponse.json(),
                catServicesResponse.json(),
                statusesResponse.json(),
                employeesResponse.json()
            ]);

            setCompanies(companiesData);
            setCatServices(catServicesData);
            setStatuses(statusesData);
            setEmployess(employeesData);
            /* */           
           
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    const openModal = () => { 

        setCompanies([...companies].filter(item => item.estado === true));
        setCatServices([...catservices].filter(item => item.estado === true));
        setStatuses([...statuses].filter(item => item.estado1 === true));
        setEmployess([...employess].filter(item => item.estado === true));

        setVerModal(!verModal)
    }

    /* Components functions */
    const closeModal = () => {
        /* */
        initializeData();
        /* */        
        setOffer(offerModelEmpty);        
        setFiles([]);
        setOfferEmployees([]);
          
        setVerModal(!verModal);
        setHidden(false);
    }
    const handleChange = (e) => {       
        
        let value;
        value = e.target.value;

        setOffer({
            ...offer,
            [e.target.name]: value
        })
    }

    function onChangeCompany(value) {
        setOffer({
            ...offer,
            ["IdFEmpresa"]: value
        })
    }
    function onChangeService(value) {
        setOffer({
            ...offer,
            ["IdFCatServicio"]: value
        })
    }
    function onChangeEmployee(value) {
        
        setOfferEmployees({
            ...offerEmployee,
            ["IdPEmpleado"]: value
        });
    }
    function onChangeStatus(value) {
        setOffer({
            ...offer,
            ["IdFEstado"]: value
        })
    }
    function onChangeOfferDate(dateString) {
        
        setOffer({
            ...offer,
            ["FechaOferta"]: moment(new Date(dateString._d)).format('DD/MM/YYYY')
        })
    }

    /*CRUD METHODS*/

    /* Get List Offers */
    const getOffers = async () => {
                
        let response = await fetch("api/offers/GetOffers", {
            method: 'GET',
            headers: {
                "Authorization": "Bearer " + auth.user
            }
        });

            if (response.ok) {
                
                let data = await response.json();

                setOffers(data.map(item => ({
                    ...item,
                    detalleOferta: (item.detalleOferta || []).filter(objeto => objeto.estado === true)
                })));
                setFilter(data.map(item => ({
                    ...item,
                    detalleOferta: (item.detalleOferta || []).filter(objeto => objeto.estado === true)
                })));
            }
    }
    function formatDate(date) {
        const formato = moment(date, ['YYYY-MM-DDTHH:mm:ss', 'DD/MM/YYYY'], true);
        return formato.isValid() ? formato.format('DD/MM/YYYY') : null;
    }

    /* Save Offers */
    const saveOffer = async () => {       
        let response;       
        
        if (files.length === 0) {
            Swal.fire(
                'Opp!',
                `Es requerido subir al menos un archivo para la oferta`,
                'error'
            );
            return;
        }
        var offerDateValid = moment(offer.FechaOferta, ["DD/MM/YYYY", "YYYY-MM-DDTHH:mm:ss"], true);

        if (!offerDateValid.isValid()) {
            Swal.fire(
                'Opp!',
                `Ha ingresado una fecha de oferta con un formato incorrecto, favor selecciona una fecha válida`,
                'error'
            );            
            return;
        }

        offer.FechaOferta = formatDate(offer.FechaOferta);

        let offerData = {
            offer: offer,
            files: files,
            offerEmployees: offerEmployee
        }
        if (offer.IdPOferta == 0) {
            response = await fetch("api/offers/SaveOffer", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8',
                    "Authorization": "Bearer " + auth.user
                },
                body: JSON.stringify(offerData)
            })

        }
        else {
            response = await fetch("api/offers/UpdateOffer", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8',
                    "Authorization": "Bearer " + auth.user
                },
                body: JSON.stringify(offerData)
            })
        }
        if (response.ok) {
            await getOffers();
            /* */
            initializeData();
            /* */           
            setOffer(offerModelEmpty);
            setFiles([])
            setOfferEmployees([])
            setVerModal(!verModal);  
            setSearch('')
        }
        else if (response.status == 409) {
            Swal.fire(
                'Opp!',
                'No se pudo guardar: ' + await response.text(),
                'warning'
            )

        }
        else {

           Swal.fire(
                'Opp!',
                'No se pudo guardar: ' + await response.text(),
                'warning')
        }
    }
  
    return (
        <>
            <Card>
                <CardHeader style={{ backgroundColor: '#4e73df', color: "white" }}>
                    Detalle de ofertas
                </CardHeader>
                <CardBody>
                    <Button color="success" size="sm" onClick={openModal}>Crear Oferta</Button>
                    <hr />
                    {offers.length > 0 &&
                        <PieOffers
                        offers={offers}
                        setOffer={setOffer}
                        setFiles={setFiles}
                        setOfferEmployees={setOfferEmployees}
                        verModal={verModal}
                        setVerModal={setVerModal}
                        setHidden={setHidden}
                        hidden={hidden}
                        search={search}
                        setSearch={setSearch}
                        filter={filter}
                        setFilter={setFilter}
                        />
                    }
                </CardBody>
            </Card>
            <Modal isOpen={verModal} size="xl" centered={true} >
                <ModalHeader>
                    Detalle Oferta
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <Col sm={6}>
                            <FormGroup>
                                <Label>Nombre Oferta</Label>
                                <Input name="NombreOferta" onChange={handleChange} value={offer.NombreOferta} disabled={hidden} />
                            </FormGroup>
                        </Col>
                        <Col sm={6}>
                            <FormGroup>
                                <Label>Servicio</Label>
                                <Select
                                    showSearch
                                    size="large"
                                    notFoundContent="Sin elementos"
                                    style={{ width: '100%' }}
                                    optionFilterProp="children"
                                    placeholder="Seleccionar Servicio..."
                                    onChange={onChangeService}
                                    filterOption={(input, option) =>
                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    defaultValue={offer.IdPOferta === 0 ? undefined : { key: offer.IdFCatServicio }}
                                    disabled={hidden}
                                >
                                    <Option value={0}>Buscar...</Option>
                                    {
                                        catservices.map((item) => {
                                            return (<Option key={item.idPCatServicio} value={item.idPCatServicio}>{item.nombreCatServicio}</Option>)
                                        })
                                    }
                                </Select>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={6}>
                            <FormGroup>
                                <Label>Clientes</Label>
                                <Select
                                    showSearch
                                    size="large"
                                    notFoundContent="Sin elementos"
                                    style={{ width: '100%' }}
                                    optionFilterProp="children"
                                    placeholder="Seleccionar Cliente..."
                                    onChange={onChangeCompany}
                                    filterOption={(input, option) =>
                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    defaultValue={offer.IdPOferta === 0 ? undefined : { key: offer.IdFEmpresa }}
                                    disabled={hidden}
                                >
                                    {
                                        companies.map((item) => {
                                            return (<Option key={item.idPEmpresa} value={item.idPEmpresa}>{item.nombreEmpresa}</Option>)
                                        })
                                    }
                                </Select>
                            </FormGroup>
                        </Col>
                        <Col sm={6}>
                            <FormGroup>
                                <Label>Colaboradores</Label>
                                <Select
                                    showSearch
                                    size="large"
                                    mode="multiple"
                                    notFoundContent="Sin elementos"
                                    style={{ width: '100%' }}
                                    optionFilterProp="children"
                                    placeholder="Seleccionar Colaboradores..."
                                    onChange={onChangeEmployee}
                                    filterOption={(input, option) =>
                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    defaultValue={offer.IdPOferta === 0 ? undefined : offerEmployee.IdPEmpleado}
                                    disabled={hidden}
                                >
                                    {
                                        employess.map((item) => {
                                            return (<Option key={item.idPEmpleado} value={item.idPEmpleado}>{item.nombreEmpleado}</Option>)
                                        })
                                    }

                                </Select>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={6}>
                            <FormGroup>
                                <Label>Estado</Label>
                                <Select
                                    showSearch
                                    size="large"
                                    notFoundContent="Sin elementos"
                                    style={{ width: '100%' }}
                                    optionFilterProp="children"
                                    placeholder="Seleccionar Estado..."
                                    onChange={onChangeStatus}
                                    filterOption={(input, option) =>
                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    defaultValue={offer.IdPOferta === 0 ? undefined : { key: offer.IdFEstado }}
                                    disabled={hidden}
                                >
                                    <Option value={0}>Buscar...</Option>
                                    {
                                        statuses.map((item) => {
                                            return (<Option key={item.idPEstado} value={item.idPEstado}>{item.nombreEstado}</Option>)
                                        })
                                    }
                                </Select>
                            </FormGroup>
                        </Col>
                        <Col xs={6}>
                            <FormGroup>
                                <Label>Fecha Oferta</Label>
                                <Datetime
                                    placeholder="Seleccionar Fecha"
                                    name="fechaDocumento"
                                    input="true"
                                    onChange={onChangeOfferDate}
                                    dateFormat="DD/MM/YYYY"
                                    value={offer.IdPOferta === 0 ? "Seleccionar" : moment(offer.FechaOferta).format('DD/MM/YYYY')}
                                    timeFormat={false}
                                    closeOnSelect={true}
                                    inputProps={{ disabled: hidden }}
                                />

                            </FormGroup>
                        </Col>

                    </Row>
                    <Row>
                        <Col xs={12}>
                            <FormGroup>
                                <Label>Descripción</Label>
                                <textarea className="form-control"
                                    name="DescripcionOferta"
                                    onChange={handleChange}
                                    value={offer.DescripcionOferta}
                                    disabled={hidden}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <FormGroup>
                                <Label>Cargar Archivo</Label>
                                <UploadFiles files={files} setFiles={setFiles} hidden={hidden} />
                            </FormGroup>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button size="sm" color="primary" onClick={saveOffer} style={{ display: hidden ? 'none' : 'block' }}>Guardar</Button>
                    <Button size="sm" color="danger" onClick={closeModal}>Cerrar</Button>
                </ModalFooter>
            </Modal>
        </>
    );


}

export default Offers;

